import React, { useEffect, useState } from 'react';
import { appendScript, removeScript } from '../../utils/scripts';
import M from 'materialize-css';
import './store.css';

const CartPage = ({ order, ...rest }) => {
   console.log({ order });
   console.log({ rest });
   useEffect(() => {
      let elems = document.querySelectorAll('.collapsible');
      M.Collapsible.init(elems, {
         // accordion: false,
         onOpenStart: function (e) {
            e.querySelector('.material-icons').innerHTML = 'remove';
         },
         onCloseStart: function (e) {
            e.querySelector('.material-icons').innerHTML = 'add';
         },
      });
      appendScript('/assets/js/css3-animate-it.js');

      return () => removeScript('/assets/js/css3-animate-it.js');
   }, []);

   return (
      <div className="row container">
         <h4 className="center white-text">Order Confirmation</h4>
         <div className="center-align col s12 m12 l12">
            <h4>
               Thanks for your order {order.billing.firstname} {order.billing.lastname}
            </h4>
            <p>
               Order ID is <b>{order && order.order && order.order.orderId}</b>
               <br />
               We'll send a confirmation email shortly to {order.billing.email}.
            </p>
         </div>
      </div>
   );
};

export default CartPage;
