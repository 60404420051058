import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import M from 'materialize-css';
import axios from 'axios';
import creditcards from './credit-cards.png';
import paypalbtn from '../../styles/paypal-button-2x.png';
import { CartContext } from '../../context/CartContext';

const Page = ({ data, history, updateData }) => {
   const { handleSubmit, watch, register, errors } = useForm({
      defaultValues: data,
   });

   const { doUpdate } = useContext(CartContext);
   const [paymentType, setPaymentType] = useState(null || data.payment.paymentType);
   const [states, setStates] = useState(null);
   const [sameAsBilling, setSameAsBilling] = useState(false);
   const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
   const year = new Date().getFullYear();
   const years = Array.from(new Array(20), (val, i) => i + year);

   const watchSameAsBilling = watch('sameAsBilling');
   const watchShippingCountry = watch('shipping.country');

   useEffect(() => {
      const loadStates = async () => {
         const apiBaseUrl = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' || window.location.hostname === '192.168.0.110' ? `http://${window.location.hostname}` : 'https://stellartoffee.com';
         const url = `${apiBaseUrl}/cfc/api/states.cfc?method=get${watchShippingCountry && watchShippingCountry.toLowerCase() === 'can' ? `&shipToCanada=${true}` : ''} `;
         axios
            .get(url)
            .then((req) => req.data)
            // .then((req) => console.log(req))
            .then((data) => setStates(data))
            .catch((er) => console.log({ er }));
         // setStates(storage.data ? storage.data : []);
      };

      loadStates();
   }, [watchShippingCountry]);

   useEffect(() => {
      setSameAsBilling(watchSameAsBilling);
      // trigger();
   }, [watchSameAsBilling]);
   console.log({ sameAsBilling });

   useEffect(() => {
      M.AutoInit();
      M.updateTextFields();
   }, [states]);

   const onSubmit = (values) => {
      const fData = {
         ...values,
         shipping: values.sameAsBilling ? values.billing : values.shipping,
         payment: {
            paymentType,
            cc: {},
         },
      };
      console.log({ fData });
      localStorage.setItem('checkout', JSON.stringify(fData));
      updateData();
      doUpdate();
      history.push('/cart.review');
   };

   // const selectedItem = { background: '#653800', color: 'white' };
   // const nonSelectedItem = { background: 'rgb(160, 148, 104)', color: 'white' };

   const selectedItem = {
      background: '#653800',
      textAlign: 'center',
      color: 'white',
   };
   const nonSelectedItem = { textAlign: 'center', color: 'black' };

   return (
      <form name="contactForm" onSubmit={handleSubmit(onSubmit)}>
         <div className="col s12 m6 l6">
            <h4>Billing Address</h4>
            <div className="row" style={{ margin: '0' }}>
               <div className="input-field col s12" style={{ marginBottom: '0' }}>
                  <input ref={register()} id="billing.company_name" name="billing.company_name" type="text" />
                  <label htmlFor="billing.company_name">Company Name (If this is wholesale order)</label>
                  {/* <span className="red-text helper-text">
							{errors.billing &&
								errors.billing.company_name &&
								errors.billing.company_name.message}
						</span> */}
               </div>
            </div>
            <div className="row" style={{ margin: '0' }}>
               <div className="input-field col s6" style={{ marginBottom: '0' }}>
                  <input ref={register({ required: 'First name is required' })} id="billing.firstname" name="billing.firstname" type="text" />
                  <label htmlFor="billing.firstname">First Name</label>
                  <span className="red-text helper-text">{errors.billing && errors.billing.firstname && errors.billing.firstname.message}</span>
               </div>
               <div className="input-field col s6" style={{ marginBottom: '0' }}>
                  <input ref={register({ required: 'Last name is required' })} id="billing.lastname" name="billing.lastname" type="text" />
                  <label htmlFor="billing.lastname">Last Name</label>
                  <span className="red-text helper-text">{errors.billing && errors.billing.lastname && errors.billing.lastname.message}</span>
               </div>
            </div>
            <div className="row" style={{ margin: '0' }}>
               <div className="input-field col s12" style={{ marginBottom: '0' }}>
                  <input ref={register({ required: 'Address 1 is required' })} id="billing.address1" name="billing.address1" type="text" />
                  <label htmlFor="billing.address1">Address 1</label>
                  <span className="red-text helper-text">{errors.billing && errors.billing.address1 && errors.billing.address1.message}</span>
               </div>
            </div>
            <div className="row" style={{ margin: '0' }}>
               <div className="input-field col s12" style={{ marginBottom: '0' }}>
                  <input ref={register()} id="billing.address2" name="billing.address2" type="text" />
                  <label htmlFor="billing.address2">Address 2 (OPTIONAL) Apartment, suite, floor, etc.</label>
               </div>
            </div>
            <div className="row" style={{ margin: '0' }}>
               <div className="input-field col s6" style={{ marginBottom: '0' }}>
                  <input ref={register({ required: 'City is required' })} id="billing.city" name="billing.city" type="text" />
                  <label htmlFor="billing.city">City</label>
                  <span className="red-text helper-text">{errors.billing && errors.billing.city && errors.billing.city.message}</span>
               </div>
               <div className="input-field col s6" style={{ marginBottom: '0' }}>
                  <label className="active" htmlFor="billing.state">
                     State
                  </label>
                  {states && (
                     <select className="browser-default" defaultValue="" ref={register({ required: 'State is required' })} id="billing.state" name="billing.state" type="text">
                        <option value="" disabled>
                           Choose your State
                        </option>
                        {states.map((state, i) => (
                           <option key={i} value={state.abbr}>
                              {state.name}
                           </option>
                        ))}
                     </select>
                  )}
                  <span className="red-text helper-text">{errors.billing && errors.billing.state && errors.billing.state.message}</span>
               </div>
            </div>
            <div className="row" style={{ margin: '0' }}>
               <div className="input-field col s6" style={{ marginBottom: '0' }}>
                  <input ref={register({ required: 'Zip/Postal Code is required' })} id="billing.zip" name="billing.zip" type="text" />
                  <label htmlFor="billing.zip">Zip/Postal Code</label>
                  <span className="red-text helper-text">{errors.billing && errors.billing.zip && errors.billing.zip.message}</span>
               </div>
               <div className="input-field col s6" style={{ marginBottom: '0' }}>
                  <label className="active" htmlFor="billing.country">
                     Country
                  </label>
                  <select className="browser-default" defaultValue="" ref={register({ required: 'Country is required' })} id="billing.country" name="billing.country" type="text">
                     {/* <option value="" disabled>
                        Choose your Country
                     </option> */}
                     <option value="USA">United States</option>
                     {/* <option value="Can">Canada</option> */}
                  </select>
                  <span className="red-text helper-text">{errors.billing && errors.billing.country && errors.billing.country.message}</span>
               </div>
            </div>
            <div className="row" style={{ margin: '0' }}>
               <div className="input-field col s6" style={{ marginBottom: '0' }}>
                  <input
                     ref={register({
                        required: 'Invalid Email Address',
                        pattern: {
                           value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                           message: 'Invalid email address',
                        },
                     })}
                     id="billing.email"
                     name="billing.email"
                     type="email"
                  />
                  <label htmlFor="billing.email">Email Address</label>
                  <span className="red-text helper-text">{errors.billing && errors.billing.email && errors.billing.email.message}</span>
               </div>

               <div className="input-field col s6" style={{ marginBottom: '0' }}>
                  <input
                     ref={register({
                        required: 'Phone is required',
                        pattern: {
                           value: /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/,
                           message: 'Invalid phone number',
                        },
                     })}
                     id="billing.phone"
                     name="billing.phone"
                     type="text"
                  />
                  <label htmlFor="billing.phone">Phone Number</label>
                  <span className="red-text helper-text">{errors.billing && errors.billing.phone && errors.billing.phone.message}</span>
               </div>
            </div>
         </div>
         <div className={`col s12 m6 l6 `}>
            <h4>Shipping Address</h4>
            <div className="row" style={{ margin: '0', height: '76px' }}>
               <div className="input-field col s12" style={{ marginBottom: '0' }}>
                  <label htmlFor="sameAsBilling">
                     <input ref={register()} name="sameAsBilling" id="sameAsBilling" type="checkbox" />
                     <span>Billing Address is same as the Shipping Address</span>
                  </label>
               </div>
            </div>
            <div className={`${watchSameAsBilling ? 'hide' : ''} row`} style={{ margin: '0' }}>
               <div className="row" style={{ margin: '0' }}>
                  <div className="input-field col s6" style={{ marginBottom: '0' }}>
                     <input
                        ref={register({
                           required: sameAsBilling ? false : 'First name is required',
                        })}
                        id="shipping.firstname"
                        name="shipping.firstname"
                        type="text"
                     />
                     <label htmlFor="shipping.firstname">First Name</label>
                     <span className="red-text helper-text">{errors.shipping && errors.shipping.firstname && errors.shipping.firstname.message}</span>
                  </div>
                  <div className="input-field col s6" style={{ marginBottom: '0' }}>
                     <input
                        ref={register({
                           required: sameAsBilling ? false : 'Last name is required',
                        })}
                        id="shipping.lastname"
                        name="shipping.lastname"
                        type="text"
                     />
                     <label htmlFor="shipping.lastname">Last Name</label>
                     <span className="red-text helper-text">{errors.shipping && errors.shipping.lastname && errors.shipping.lastname.message}</span>
                  </div>
               </div>
               <div className="row" style={{ margin: '0' }}>
                  <div className="input-field col s12" style={{ marginBottom: '0' }}>
                     <input
                        ref={register({
                           required: sameAsBilling ? false : 'Address 1 is required',
                        })}
                        id="shipping.address1"
                        name="shipping.address1"
                        type="text"
                     />
                     <label htmlFor="shipping.address1">Address 1</label>
                     <span className="red-text helper-text">{errors.shipping && errors.shipping.address1 && errors.shipping.address1.message}</span>
                  </div>
               </div>
               <div className="row" style={{ margin: '0' }}>
                  <div className="input-field col s12" style={{ marginBottom: '0' }}>
                     <input ref={register()} id="shipping.address2" name="shipping.address2" type="text" />
                     <label htmlFor="shipping.address2">Address 2 (OPTIONAL) Apartment, suite, floor, etc.</label>
                  </div>
               </div>
               <div className="row" style={{ margin: '0' }}>
                  <div className="input-field col s6" style={{ marginBottom: '0' }}>
                     <input
                        ref={register({
                           required: sameAsBilling ? false : 'City is required',
                        })}
                        id="shipping.city"
                        name="shipping.city"
                        type="text"
                     />
                     <label htmlFor="shipping.city">City</label>
                     <span className="red-text helper-text">{errors.shipping && errors.shipping.city && errors.shipping.city.message}</span>
                  </div>
                  <div className="input-field col s6" style={{ marginBottom: '0' }}>
                     <label className="active" htmlFor="shipping.state">
                        State
                     </label>
                     {states && (
                        <select
                           defaultValue=""
                           className="browser-default"
                           ref={register({
                              required: sameAsBilling ? false : 'State is required',
                           })}
                           id="shipping.state"
                           name="shipping.state"
                           type="text"
                        >
                           <option value="" disabled>
                              Choose your State
                           </option>
                           {states.map((state, i) => (
                              <option key={i} value={state.abbr}>
                                 {state.name}
                              </option>
                           ))}
                        </select>
                     )}
                     <span className="red-text helper-text">{errors.shipping && errors.shipping.state && errors.shipping.state.message}</span>
                  </div>
               </div>
               <div className="row" style={{ margin: '0' }}>
                  <div className="input-field col s6" style={{ marginBottom: '0' }}>
                     <input
                        ref={register({
                           required: sameAsBilling ? false : 'Zip is required',
                        })}
                        id="shipping.zip"
                        name="shipping.zip"
                        type="text"
                     />
                     <label htmlFor="shipping.zip">Zip/Postal Code</label>
                     <span className="red-text helper-text">{errors.shipping && errors.shipping.zip && errors.shipping.zip.message}</span>
                  </div>
                  <div className="input-field col s6" style={{ marginBottom: '0' }}>
                     <label className="active" htmlFor="shipping.country">
                        Country
                     </label>
                     <select
                        defaultValue=""
                        className="browser-default"
                        ref={register({
                           required: sameAsBilling ? false : 'Stateountry is required',
                        })}
                        id="shipping.country"
                        name="shipping.country"
                        type="text"
                     >
                        {/* <option value="" disabled>
                           Choose your Country
                        </option> */}
                        <option value="USA">United States</option>
                        {/* <option value="Can">Canada</option> */}
                     </select>
                     <span className="red-text helper-text">{errors.shipping && errors.shipping.country && errors.shipping.country.message}</span>
                  </div>
               </div>
            </div>
         </div>
         <div className="clearfix"></div>
         <div className="col s12 m6 l6">
            <h4>Payment</h4>
            <p style={{ fontSize: '1rem' }}>Select type of payment to checkout</p>
            {/* <PaymentForm type="PP" /> */}
            <div className="collection">
               <div onClick={() => setPaymentType('paypal')} className={`collection-item ${paymentType === 'paypal' && 'active'}`} style={paymentType === 'paypal' ? selectedItem : nonSelectedItem}>
                  <img src={paypalbtn} style={{ height: '40px' }} alt="" />
               </div>
               <div onClick={() => setPaymentType('card')} className={`collection-item ${paymentType === 'card' && 'active'}`} style={paymentType === 'card' ? selectedItem : nonSelectedItem}>
                  <img src={creditcards} style={{ height: '40px' }} alt="" />
               </div>
            </div>

            <input ref={register()} id="payment.cc" name="payment.cc" type="hidden" />
            <input ref={register()} id="payment.month" name="payment.month" type="hidden" />
            <input ref={register()} id="payment.year" name="payment.year" type="hidden" />
            <input ref={register()} id="payment.cvv" name="payment.cvv" type="hidden" />

            {/* <div className="row" style={{ margin: '0' }}>
               <div className="input-field col s12" style={{ marginBottom: '0' }}>
                  <input ref={register({ required: 'Credit Card Number is required' })} id="payment.cc" name="payment.cc" type="text" />
                  <label htmlFor="payment.cc">Credit Card Number</label>
                  <span className="red-text helper-text">{errors.payment && errors.payment.cc && errors.payment.cc.message}</span>
               </div>
            </div> */}

            {/* <div className="row" style={{ margin: '0' }}>
               <div className="input-field col s4" style={{ marginBottom: '0' }}>
                  <label className="active" htmlFor="payment.month">
                     Expiration Date
                  </label>
                  <select defaultValue="" className="browser-default" ref={register({ required: 'Expiration Month is required' })} id="payment.month" name="payment.month" type="text">
                     <option value="" disabled>
                        Month
                     </option>
                     {}
                     {months.map((el, i) => (
                        <option key={i} value={el}>
                           {el}
                        </option>
                     ))}
                  </select>
                  <span className="red-text helper-text">{errors.payment && errors.payment.month && errors.payment.month.message}</span>
               </div> */}

            {/* <div className="input-field col s4" style={{ marginBottom: '0' }}>
                  <select defaultValue="" className="browser-default" ref={register({ required: 'Expiration Year is required' })} id="payment.year" name="payment.year" type="text">
                     <option value="" disabled>
                        Year
                     </option>
                     {years.map((el, i) => (
                        <option key={i} value={el}>
                           {el}
                        </option>
                     ))}
                  </select>
                  <span className="red-text helper-text">{errors.payment && errors.payment.year && errors.payment.year.message}</span>
               </div> */}

            {/* <div className="input-field col s4" style={{ marginBottom: '0' }}>
                  <input ref={register({ required: 'Security Code is required' })} id="payment.cvv" name="payment.cvv" type="text" />
                  <label htmlFor="payment.cvv">Security Code (CVV)</label>
                  <span className="red-text helper-text">{errors.payment && errors.payment.cvv && errors.payment.cvv.message}</span>
               </div>
            </div> */}
         </div>
         <div className="col s12 m6 l6">
            <h4>Send a Note</h4>
            <div className="row" style={{ margin: '0' }}>
               <div className="input-field col s12">
                  <textarea
                     placeholder="Your Special Instructions/Notes to Us (E.G. Gift Item, Special Occasion, Festival, Requested Ship Date, Suggestions, Other Items, Etc.)"
                     ref={register()}
                     id="notes"
                     name="notes"
                     className="materialize-textarea"
                     style={{ height: '115px', paddingTop: '1rem' }}
                  ></textarea>
                  {/* <label htmlFor="notes">Your Special Instructions/Notes to Us (E.G. Gift Item, Special Occasion, Festival, Requested Ship Date, Suggestions, Other Items, Etc.)</label> */}
               </div>
            </div>
            <br />
            <div className="row center" style={{ margin: '0', paddingLeft: '10px' }}>
               {/* <Link to="cart.review" className="goldBtn black" type="submit">
                  Continue to Review
               </Link> */}
               <button className="btn" type="submit">
                  Continue to Review
               </button>
            </div>
         </div>
      </form>
   );
};

const PaymentForm = ({ type }) => {
   return (
      <>
         {type.toLowerCase() === 'PE'.toLowerCase() && <>Payeazy</>}
         {type.toLowerCase() === 'PP'.toLowerCase() && <>Paypal</>}
      </>
   );
};

export default Page;
