import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const HomeLayout = (props) => {
	const path = props.children.props.location.pathname;

	return (
		<div id="wrapper" style={{ overflow: "hidden" }}>
			<Header path={path} template="home" />
			{path === "/" ? (
				<div>{props.children}</div>
			) : (
				<div
					className=""
					style={{ paddingTop: "325px", paddingBottom: "170px" }}
				>
					{props.children}
				</div>
			)}
			<Footer template="home" />
		</div>
	);
};

export default HomeLayout;
