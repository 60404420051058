import React, { createContext, useState, useEffect, useContext } from 'react';
import authService from '../../services/users';
import Cookies from 'js-cookie';

const noop = () => {};

export const AuthContext = createContext({
   user: null,
   cart: null,
   login: noop,
   logout: noop,
});

export const Provider = (props) => {
   const [user, setUser] = useState(Cookies.getJSON('authToken') || null);
   // const [cart, setCart] = useState({} || null);
   const cart = {} || null;

   useEffect(() => {
      const currentUser = new authService().getUserFromToken();
      if (currentUser) {
         setUser(currentUser);
      }
   }, []);

   const reload = async () => {
      console.log(user);
      return await new authService().reloadUser(user.user.id, user.token).then((json) => {
         if (json) {
            setUser(json);
            Cookies.set('authToken', JSON.stringify(json), { expires: 1 });
            return json;
         }
      });
   };

   const logout = () => {
      Cookies.remove('authToken');
      setUser(null);
   };

   const login = async (username, password) => {
      return await new authService()
         .login(username, password)
         .then((res) => {
            if (res) {
               setUser(res);
               Cookies.set('authToken', JSON.stringify(res), { expires: 1 });
               return res;
            }
         })
         .catch(() => null);
   };

   const auth = { user, cart, login, logout, reload };

   return <AuthContext.Provider value={auth} {...props} />;
};

export const useAuthentication = () => useContext(AuthContext);

// export const WithAuthentication = ({ children }) => children(useAuthentication());

const withAuthentication = (Comp) => (props) => {
   const auth = useAuthentication();
   return <Comp auth={auth} {...props} />;
};

export default withAuthentication;
