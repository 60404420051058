import React, { useState, useEffect } from 'react';
import { InTouch as InTouchOrderInfo } from '../../components/InTouchOrderInfo';
import ProductItem from '../../components/ProductItem';
import ShowProduct from '../../components/ShowProduct';
import productService from '../../services/products';
import categoryService from '../../services/categories';
import Loading from '../../components/Loader';
import productBox from '../../images/Stellar-product-box.png';

// import sauces from './image268809.png';
// import marinade from './image976330.png';

const ProductPage = (props) => {
   const [active, setActive] = useState(0);
   const [open, setOpen] = useState(false);
   const [fetching, setFetching] = useState(true);
   const [fetchError, setFetchError] = useState(false);
   const [products, setProducts] = useState([]);
   const [category, setCategory] = useState({});

   const urlParams = props.match.params;

   // useEffect(() => , [urlParams.id]);

   useEffect(() => {
      let rendered = true;

      function getProducts() {
         console.log('fetching');
         setFetching(true);
         new productService()
            .getProducts()
            .then((json) => {
               if (json && rendered) {
                  setProducts(json);
               }
            })
            .catch((e) => setFetchError(true))
            .finally((e) => setFetching(false));
      }

      getProducts();

      return () => (rendered = false);
   }, []);

   console.log(products.length);

   const productClicked = (e) => {
      let x = products.reduce((val, cur) => {
         val.push(cur.id);
         return val;
      }, []);

      setActive(x.indexOf(e));
      setOpen(true);
      window.scrollTo(0, 0);
   };

   const showProducts = () => {
      if (fetching) {
         return <Loading />;
      } else if (fetchError) {
         return <h1>Unable to load products</h1>;
      }

      return <ShowProduct {...products[active]} />;
   };

   const productClose = () => {
      setOpen(false);
   };

   const variants = {
      open: {
         opacity: 1,
         // y: 0,
         height: '100%',
         boxShadow: 'rgba(9, 9, 9, 0.52) 0px 0px 30px',
         marginBottom: '50px',
      },
      closed: {
         opacity: 0,
         // y: '-100%',
         height: '0',
      },
   };

   if (fetching) {
      return (
         <div
            style={{
               background: 'transparent',
               minHeight: '500px',
               position: 'relative',
            }}
         >
            <Loading />
         </div>
      );
   }

   const productArrClass = (count) => {
      let cl = 'col s6 m4 l3 noPadding';
      return '';
      switch (count) {
         case 1:
            cl = 'col s6 m4 l3 offset-s3 offset-m4 offset-l4';
            break;
         case 2:
            cl = 'col s6 m4 l3 offset-m1 offset-l2';
            break;
         case 3:
            cl = 'col s6 m4 l3 offset-l1';
            break;
         default:
            break;
      }
      return cl;
   };

   return (
      <div className="">
         <div style={open ? { overflow: 'hidden', position: 'relative', ...variants.open } : { overflow: 'hidden', position: 'relative', ...variants.closed }}>
            <a href="#!" onClick={productClose} className="closeBtn">
               <i className="far fa-times-circle"></i>
            </a>
            {showProducts()}
         </div>
         <div className="container center row">
            {/* <h1 className="center-align">{category.name}</h1> */}
            <div className="flex-row">
               <div className="col s12 m12 l10 offset-l1">
                  {products.map((p, i) => (
                     <div key={i} className="col s12 m6 l6">
                        <div className="center product-wrapper" style={{ paddingTop: '0' }}>
                           <div className="product-spacer">
                              <ProductItem cl={productArrClass(products.length)} key={`pitem_${p.id}`} trigger={productClicked} {...p} />
                           </div>
                        </div>
                     </div>
                  ))}
               </div>
            </div>
            <p
               style={{
                  fontSize: '1.8rem',
                  lineHeight: '1.2',
                  fontWeight: '300',
               }}
            >
               <b>
                  Each 8 oz box is $15 with free shipping in US
                  <br />
                  and a 10% discount for nine boxes or more.
               </b>
            </p>
         </div>
         {/* <InTouchOrderInfo /> */}
         <div className="clearfix"></div>
      </div>
   );
};

export default ProductPage;
