import React, { useState } from "react";
// import CurrencyFormat from 'react-currency-format';
// import { useForm } from 'react-hook-form';
// import M from 'materialize-css';

// const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const year = new Date().getFullYear();
// const years = Array.from(new Array(15), (val, i) => i + year);
// const round = (number, decimalPlaces) => Number(Math.round(number + 'e' + decimalPlaces) + 'e-' + decimalPlaces);
// const currencyAttr = {
//    decimalScale: 2,
//    fixedDecimalScale: true,
//    displayType: 'text',
//    thousandSeparator: true,
//    prefix: '$',
// };
// const helperText = { height: '18px' };

const initState = {
	order: {
		firstname: "",
		lastname: "",
		email: "",
		phone: "",
		amount: 0,
	},
	billing: {
		company: "",
		firstname: "",
		lastname: "",
		address1: "",
		address2: "",
		city: "",
		state: "",
		zip: "",
		country: "",
	},
	shipping: {
		firstname: "",
		lastname: "",
		address1: "",
		address2: "",
		city: "",
		state: "",
		zip: "",
		country: "",
	},
	payment: {
		paymentType: "",
		cc: "",
		month: "",
		year: "",
		cvv: "",
	},
};

const Form = ({ form }) => {
	console.log({ form });
	return (
		<div
			className="card goldBg z-depth-4 white-text"
			style={{ padding: "0 25px" }}
		>
			<p
				className="robotoSlab"
				style={{
					fontSize: "2rem",
					lineHeight: "1",
					margin: "1rem 0 0",
					paddingTop: "30px",
				}}
			>
				START YOUR REVIEW
			</p>
			<p
				style={{
					fontSize: "1.5rem",
					lineHeight: "1.2",
					fontWeight: "300",
					marginTop: "0",
				}}
			>
				LAUNCH YOUR ORDER
			</p>

			{/* <button onClick={() => setOrderValidate(orderValidate + 1)}>
				asdasd
			</button> */}

			<div
				className="valign-wrapper left-align"
				style={{ borderBottom: "1px solid #dddddd" }}
			>
				<div className="col s5">
					<p>ORDER INFO:</p>
				</div>
				<div className="col s7">
					<p style={{ fontWeight: "300" }}>
						James Franco
						<br />
						JamesFranco@yahoo.com
						<br />
						912-111-2222
						<br />
						Box(es) order: 5
						<br />
						Total: $22.00
					</p>
				</div>
			</div>

			<div
				className="valign-wrapper left-align"
				style={{ borderBottom: "1px solid #dddddd" }}
			>
				<div className="col s5">
					<p>BILLING INFO:</p>
				</div>
				<div className="col s7">
					<p style={{ fontWeight: "300" }}>
						James Franco
						<br />
						321 Main St.
						<br />
						Savannah, Georgia
						<br />
						31234 USA
					</p>
				</div>
			</div>

			<div
				className="valign-wrapper left-align"
				style={{ borderBottom: "1px solid #dddddd" }}
			>
				<div className="col s5">
					<p>SHIPPING INFO:</p>
				</div>
				<div className="col s7" style={{ fontWeight: "300" }}>
					<p>Same as Billing Address</p>
					{/* <p>
						James Franco
						<br />
						321 Main St.
						<br />
						Savannah, Georgia
						<br />
						31234 USA
					</p> */}
				</div>
			</div>

			<div className="valign-wrapper left-align">
				<div className="col s5">
					<p>PAYMENT INFO:</p>
				</div>
				<div className="col s7" style={{ fontWeight: "300" }}>
					<p>
						41111111111111111
						<br />
						05/2022
						<br />
						332
					</p>
				</div>
			</div>
			<div className="clearfix"></div>

			<div>
				<button className="redBtn">LAUNCH</button>
			</div>
			<br />
			<br />

			<div className="clearfix"></div>
		</div>
	);
};

const PayeezyForm = () => {
	return;
};

export default Form;
