import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import ArrowToTop from '../ScrollTopArrow';
import '../../styles/app.css';
import '../../styles/set2.css';
import NavLinks, { NavLinksLeft } from './navlinks';
import { CartContext } from '../../context/CartContext';
import M from 'materialize-css';
import logo from '../../images/Stellar-logo.png';
import cartImg from '../../images/CART.png';
// import butterToffee from "./Stellar-hero.png";
import butterToffee1 from '../../images/Stellar-hero-FLAT.png';
// import butterToffee2 from "../../Stellar-hero-ROUND.png";
// import { motion } from "framer-motion";

const topStyle = {
   position: 'absolute',
   border: '0',
   boxShadow: '0 0 0',
};

const interLogoStyle = {
   left: '0',
   transform: 'none',
};

const Home = ({ path, ...props }) => {
   useEffect(() => {
      M.Sidenav.init(document.getElementById('mobile-nav'));
   }, []);

   const closeMobileNav = () => {
      let elem = document.getElementById('mobile-nav');
      let instance = M.Sidenav.getInstance(elem);
      instance.close();
   };

   const { itemCount } = useContext(CartContext);

   return (
      <div className="homeLanding">
         <div className="row container">
            <div className="nav-wrapper">
               <header className="" style={{ position: 'relative', zIndex: 200 }}>
                  <nav className="transparent" style={topStyle}>
                     <a
                        href="#!"
                        data-target="mobile-nav"
                        className="sidenav-trigger black-text"
                        style={{
                           margin: '0',
                           padding: '0 20px',
                           position: 'fixed',
                           top: '0',
                           background: 'white',
                        }}
                     >
                        <i
                           className="material-icons"
                           style={{
                              fontSize: '40px',
                              color: '#8f8f8f',
                              // marginTop: '15px',
                           }}
                        >
                           menu
                        </i>
                     </a>

                     {/* <Link className="brand-logo" style={path === '/' ? {} : interLogoStyle} to="/"> */}

                     <Link className="brand-logo" to="/">
                        <img src={logo} alt="" className="logoAdj" style={{ marginTop: '50px' }} />
                     </Link>
                     {/* <ul
								className="left hide-on-med-and-down"
								style={{ margin: "50px 0 0 100px" }}
							>
								<NavLinksLeft closeMobileNav={closeMobileNav} />
							</ul> */}
                     <div className="right">
                        <a
                           href="/Cart"
                           className="black-text"
                           onClick={() => closeMobileNav()}
                           style={{
                              position: 'relative',
                              float: 'right',
                              margin: '6px 36px 0 0',
                              height: '45px',
                           }}
                        >
                           <img src={cartImg} />
                           <span className="cartNumItem">{itemCount}</span>
                        </a>
                        <div className="clearfix"></div>
                        <ul className="right hide-on-med-and-down" style={{ margin: '0px 20px 0 0' }}>
                           <NavLinks closeMobileNav={closeMobileNav} itemCount={itemCount} />
                        </ul>
                     </div>
                  </nav>
               </header>
            </div>
            <div className="clearfix"></div>
            {/* {path === "/" && (
					<div>
						<div style={{ paddingTop: "330px" }}>
							<div
								className="col s12 m12 l12 center-align animatedParent animateOnce"
								data-appear-top-offset="300"
							>
								<img
									src={butterToffee1}
									style={{ position: "relative", zIndex: "100" }}
									alt=""
									className="responsive-img animated fadeInUpShort"
								/>
							</div>
						</div>
					</div>
				)} */}
         </div>
         <ul className="sidenav uppercaseTxt" id="mobile-nav">
            <li style={{ height: '230px' }}>
               <Link className="" to="/" style={{ border: '0' }} onClick={() => closeMobileNav()}>
                  <img src={logo} alt="" className="responsive-img" style={{ marginTop: '15px' }} />
               </Link>
            </li>
            <NavLinks closeMobileNav={closeMobileNav} />
            <NavLinksLeft closeMobileNav={closeMobileNav} />

            {/* <p className="ssIcon center">
					<a
						href="https://g.page/FoxandWeeks?share"
						target="_blank"
						className="fbIcon"
					>
						<i className="fas fa-map-pin"></i>
					</a>
					<br />
					7200 Hodgson Memorial Drive <br />
					Savannah, GA 31406
					<br />
					912-352-7200
				</p> */}
         </ul>

         <ArrowToTop />
      </div>
   );
};

export default React.memo(Home);
