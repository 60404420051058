export const createReducer = () => BlogReducer;

export const BlogReducer = (state, action) => {
   switch (action.type) {
      case 'SET_FETCHING':
         return {
            ...state,
            fetching: action.payload,
         };
      case 'SET_POSTS':
         return {
            ...state,
            fetching: false,
            posts: action.payload,
         };
      default:
         return state;
   }
};
