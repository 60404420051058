import React, { useState } from "react";
import RegisterForm from "./register";
import LoginForm from "./login";

import "./login.scss";

const LoginPage = ({ auth, ...props }) => {
	// const [username, setUsername] = useState("");
	// const [password, setPassword] = useState("");
	const [form, setForm] = useState("register");
	const toggle = {
		login: "register",
		register: "login",
	};

	const openLogin = (e) => {
		if (e.target.id !== form) setForm(toggle[form]);
	};

	return (
		<div className="login-box">
			<div className="container z-depth-2">
				<div className="stripes">
					<div className="stripe stripe1"></div>
					<div className="stripe stripe2"></div>
					<div className="stripe stripe3"></div>
					<div className="stripe stripe4"></div>
					<div className="stripe stripe5"></div>
					<div className="stripe stripe6"></div>
					<div className="stripe stripe7"></div>
				</div>
				<div className="form-div">
					<div className="form-structor">
						<div className={form === "register" ? "login slide-up" : "login"}>
							<div className="centerx">
								<p className="form-title" id="login" onClick={openLogin}>
									<span>or</span>Wholesale Member Login
								</p>
								<LoginForm auth={auth} />
							</div>
						</div>
						<div className={form === "login" ? "signup slide-up" : "signup"}>
							<p className="form-title" id="signup" onClick={openLogin}>
								<span>or</span>
								Register Wholesale
							</p>
							<RegisterForm {...props} auth={auth} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LoginPage;
