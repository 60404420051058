import React, { useEffect, useState } from 'react';
import Loading from '../../components/Loader';
import './store.css';
import Axios from 'axios';
import { API_URL } from '../../config';

const CartPage = ({ match }) => {
   // const [orders, setOrders] = useState(null);
   const [order, setOrder] = useState(null);
   const [loading, setLoading] = useState(true);

   useEffect(() => {
      const fetchOrders = () => {
         Axios({
            method: 'GET',
            url: `${API_URL}orders.cfc?method=getOrderByOrderId&i=${match.params.oid}`,
            headers: {
               'Content-Type': 'application/json',
            },
         })
            .then((r) => r.status === 200 && r.data)
            .then((d) => d && setOrder(d))
            .catch((e) => console.log({ e }))
            .finally((x) => setLoading(false));
      };

      match.params.oid && fetchOrders();
   }, [match.params.oid]);

   console.log({ order });
   if (loading) return <Loading />;

   return (
      <div className="row container">
         <h1 className="center">Order Confirmation</h1>
         {!order && <div className="center-align">Unable to find your order</div>}
         {order && (
            <div className="col s12 m12 l12">
               <h4>Thanks for your order {`${order.billing.firstname} ${order.billing.lastname}`}</h4>
               <p>
                  Order ID is{' '}
                  <b>
                     {order.order.ORDERID && order.order.ORDERID}
                     {order.order.orderid && order.order.orderid}
                  </b>
                  <br />
                  We'll send a confirmation email shortly to {order.billing.email}.
               </p>
            </div>
         )}
      </div>
   );
};

export default CartPage;
