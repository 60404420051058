import React from 'react';
import { Helmet } from 'react-helmet';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import BlogContext from '../../context/blog-context';
import Blog, { Post_Loader } from './page';

export const Page = ({ match }) => {
  let { path, url, ...rest } = useRouteMatch();

  return (
    <>
      <BlogContext>
        <Helmet>
          <title>Blog : Stellar : Butter Toffee - IT'S OUT OF THIS WORLD</title>
        </Helmet>
        <Switch>
          <Route exact path={`${path}`} component={Blog} />
          <Route exact path={`${path}/:post`} component={Post_Loader} />
          <Route exact path={`${path}/search/:q`} component={Blog} />
          {/* <Route exact path={`${path}/new`} component={CreateTopic} />
               <Route exact path={`${path}/p/:topic`} component={Topic} /> */}
        </Switch>
      </BlogContext>
    </>
  );
};

export default Page;

// match: isExact: true;
// params: {
// }
// path: '/Forum';
// url: '/Forum';
