import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import withAuthentication, { useAuthentication } from './context/auth-provider';

import HomePageLayout from './Layout/homePage';
import ProductPageLayout from './Layout/productPage';
import LoginPageLayout from './Layout/login';
import DefaultLayout from './Layout/default';
import AdminLayout from './Layout/admin';
import LoginPage from './containers/LoginPage';

//

export function LoginRoute({ component: Component, ...rest }) {
   const auth = useAuthentication();
   const history = useHistory();

   if (auth.user) {
      history.push('/authenticated');
   }

   return (
      <Route
         {...rest}
         render={(props) => (
            <LoginPageLayout>
               <Component {...props} />
            </LoginPageLayout>
         )}
      />
   );
}

export function HomeRoute({ component: Component, ...rest }) {
   return (
      <Route
         {...rest}
         render={(props) => (
            <HomePageLayout>
               <Component {...props} />
            </HomePageLayout>
         )}
      />
   );
}

export function ProductRoute({ component: Component, ...rest }) {
   return (
      <Route
         {...rest}
         render={(props) => (
            <ProductPageLayout>
               <Component {...props} />
            </ProductPageLayout>
         )}
      />
   );
}

export function AdminRoute({ component: Component, ...rest }) {
   return (
      <Route
         {...rest}
         render={(props) => (
            <AdminLayout history={props.history}>
               <Component {...props} />
            </AdminLayout>
         )}
      />
   );
}

export function PrivateRoute({ component: Component, ...rest }) {
   const auth = useAuthentication();
   const Output = auth.user ? Component : withAuthentication(LoginPage);

   if (auth.user) {
      // console.log(auth.user);
      return <HomeRoute {...rest} component={(props) => <Output {...props} />} />;
   }

   console.log('go login');
   return <HomeRoute exact path={rest.path} component={(props) => <Output {...props} />} {...rest} />;
}

export default ({ component: Component, ...rest }) => {
   return (
      <Route
         {...rest}
         render={(props) => (
            <DefaultLayout>
               <Component {...props} />
            </DefaultLayout>
         )}
      />
   );
};
