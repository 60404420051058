import React, { createContext, useReducer, useEffect } from 'react';
import { createReducer, sumItems } from './CartReducer';
import M from 'materialize-css';

export const CartContext = createContext();

// const storage = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [];
const __sumItems = sumItems([]);
const initialState = { cartItems: [], ...__sumItems, checkout: false, coupon: null };

const CartContextProvider = ({ children }) => {
   const memorizedReducer = React.useCallback(createReducer(), []);
   const [state, dispatch] = useReducer(memorizedReducer, initialState, () => {
      const localDataStore = localStorage.getItem('cart');
      const localData = localDataStore ? JSON.parse(localDataStore) : [];

      const localDataCoupon = localStorage.getItem('coupon');
      const localCoupon = localDataCoupon ? JSON.parse(localDataCoupon) : null;
      const _sumItems = sumItems(localData);
      return { cartItems: localData, ..._sumItems, checkout: false, ...localCoupon };
   });

   useEffect(() => {
      localStorage.setItem('cart', JSON.stringify(state.cartItems.length > 0 ? state.cartItems : []));
   }, [state.cartItems]);

   const increase = (payload) => {
      dispatch({ type: 'INCREASE', payload });
      M.toast({ html: `${payload.name} added to your cart!` });
   };

   const decrease = (payload) => {
      dispatch({ type: 'DECREASE', payload });
      M.toast({ html: `${payload.name} was removed from your cart!` });
   };

   const doUpdate = () => {
      dispatch({ type: 'UPDATE' });
   };

   const setCoupon = (payload) => {
      console.log('SET COUPON', { payload });
      dispatch({ type: 'SET_COUPON', payload });
      M.toast({ html: `${payload.coupon.code} was applied to your cart!` });
   };

   const removeCoupon = () => {
      localStorage.removeItem('coupon');
      console.log('REMOVE COUPON');
      dispatch({ type: 'REMOVE_COUPON' });
      M.toast({ html: `Coupon removed!` });
   };

   const addProduct = (payload) => {
      dispatch({ type: 'ADD_ITEM', payload });
      M.toast({ html: `${payload.name} added to your cart!` });
   };

   const addWholeSaleProduct = (payload) => {
      dispatch({ type: 'ADD_WHOLESALE_ITEM', payload });
      M.toast({ html: `${payload.name} added to your cart!` });
   };

   const removeProduct = (payload) => {
      dispatch({ type: 'REMOVE_ITEM', payload });
      M.toast({ html: `All ${payload.name} was removed from your cart!` });
   };

   const clearCart = () => {
      dispatch({ type: 'CLEAR' });
      // M.toast({ html: `All items removed!` });
   };

   const handleCheckout = () => {
      console.log('CHECKOUT', state);
      dispatch({ type: 'CHECKOUT' });
   };

   const contextValues = {
      addWholeSaleProduct,
      removeProduct,
      removeCoupon,
      setCoupon,
      addProduct,
      increase,
      decrease,
      doUpdate,
      clearCart,
      handleCheckout,
      ...state,
   };

   return <CartContext.Provider value={contextValues}>{children}</CartContext.Provider>;
};

export default CartContextProvider;
