import React, { useState, useEffect } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

const mapStyles = {
	width: "100%",
	height: "75vh",
};

const areEqual = (prevProps, nextProps) =>
	prevProps.markers === nextProps.markers;

const MapContainer = (props) => {
	const [bounds, setBounds] = useState([]);
	console.log(props.markers);

	useEffect(() => {
		let b = new props.google.maps.LatLngBounds();

		for (var i = 0; i < props.markers.length; i++) {
			b.extend((({ lat, lng }) => ({ lat, lng }))(props.markers[i]));
		}

		setBounds(b);
	}, [props.markers, props.google.maps.LatLngBounds]);

	return (
		<React.Fragment>
			<Map
				google={props.google}
				zoom={14}
				style={mapStyles}
				initialCenter={{
					lat: 42.39,
					lng: -72.52,
				}}
				bounds={bounds}
			>
				{props.markers.map((el) => {
					if (el.lat && el.lng)
						return (
							<Marker
								key={el.id}
								onClick={props.onMarkerClick}
								{...el}
								position={(({ lat, lng }) => ({ lat, lng }))(el)}
								icon={{
									url: "/assets/images/DrPetes-PIN.png",
									scaledSize: new props.google.maps.Size(15, 30),
								}}
							/>
						);
					return null;
				})}
			</Map>
		</React.Fragment>
	);
};

export default GoogleApiWrapper((props) => ({
	apiKey: props.apiKey,
}))(React.memo(MapContainer, areEqual));
