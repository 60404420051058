import React, { useContext } from 'react';
// import { Link } from 'react-router-dom';
import { CartContext } from '../../context/CartContext';
// import ReactDOMServer from "react-dom/server";
import CurrencyFormat from 'react-currency-format';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import star from '../../images/stellar-white-start.png';
import productBox from '../../images/Stellar-product-box.png';
import toffeeAlmond from '../../images/Stellar-hero-FLAT.png';

const round = (number, decimalPlaces) => Number(Math.round(number + 'e' + decimalPlaces) + 'e-' + decimalPlaces);
const currencyAttr = {
   decimalScale: 2,
   fixedDecimalScale: true,
   displayType: 'text',
   thousandSeparator: true,
   prefix: '$',
};
const settings = {
   dots: true,
   arrows: false,
   autoplay: true,
   infinite: true,
   speed: 500,
   slidesToShow: 1,
   slidesToScroll: 1,
};

const Product = (product) => {
   // const des = innerHtml(description);
   // const htmlString = ReactDOMServer.renderToStaticMarkup(description);

   const { addProduct, cartItems, increase } = useContext(CartContext);

   const isInCart = (product) => {
      return !!cartItems.find((item) => item.id === product.id);
   };

   const addProductToCart = (product) => {
      console.log('add Clicked', { product });
      addProduct(product);
   };

   const addMoreProductToCart = (product) => {
      console.log('increase Clicked');
      increase(product);
   };

   const URL = 'https://stellartoffee.com';

   return (
      <div style={{ backgroundColor: '#a09468' }}>
         <div className="container row displayAdj">
            <div className="col s12 m6 l5" style={{ padding: '50px 0' }}>
               <Slider {...settings}>
                  {product.images.map((image, i) => (
                     <div key={i}>
                        <img key={i} src={`${URL}/assets/products/giant/${image}`} className="responsive-img productImgAdj" alt="" />
                     </div>
                  ))}
               </Slider>
            </div>
            <div className="col s12 m6 l7 productInfoAdj">
               <div className="hero-text center-align white-text">
                  {/* <p className="bestSellerBtn">Best Seller</p> */}
                  <h2
                     className="robotoSlab"
                     style={{
                        margin: '0 0px 40px',
                     }}
                  >
                     {product.name}
                  </h2>
                  {/* <p className="mediaIcon-small">
							<a href="#!">
								<i className="fab fa-pinterest-p ssIcon-small"></i>
							</a>{" "}
							<a href="#!">
								<i className="far fa-paper-plane ssIcon-small"></i>
							</a>
						</p> */}
                  <div dangerouslySetInnerHTML={{ __html: product.description }} />
                  <p>
                     PRICE: <CurrencyFormat {...currencyAttr} value={round(product.price, 2)} />
                  </p>
                  <br />

                  {isInCart(product) && (
                     <button
                        onClick={() => addMoreProductToCart(product)}
                        className="btn"
                        style={{
                           zIndex: 100,
                           position: 'relative',
                           marginBottom: '15px',
                        }}
                     >
                        ADD TO ORDER
                     </button>
                  )}

                  {!isInCart(product) && (
                     <button
                        onClick={() => addProductToCart(product)}
                        className="btn"
                        style={{
                           zIndex: 100,
                           position: 'relative',
                           marginBottom: '15px',
                        }}
                     >
                        ADD TO CART
                     </button>
                  )}
                  <img className="star" src={star} style={{ position: 'absolute', left: '50%', top: '61%' }} />
                  <img
                     className="star"
                     src={star}
                     style={{
                        position: 'absolute',
                        right: '15%',
                        top: '52%',
                        width: '40px',
                        transform: 'rotate(20deg)',
                     }}
                  />
                  <img
                     className="star"
                     src={star}
                     style={{
                        position: 'absolute',
                        right: '8%',
                        top: '20%',
                        transform: 'rotate(20deg)',
                     }}
                  />
                  {/* <a
							href="#Order-Information"
							className="btn transparent"
							style={{
								zIndex: 100,
								position: "relative",
								marginBottom: "15px",
							}}
						>
							ORDER INFORMATION
						</a> */}
               </div>
            </div>
         </div>
      </div>
   );
};

export default Product;
