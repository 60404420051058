import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import M from 'materialize-css';
import logoWhite from '../../images/Stellar-logo-white.png';
import Axios from 'axios';
import { API_URL } from '../../config';

// const topStyle = {};

const Home = (props) => {
   let { location } = useHistory();

   const formRef = React.useRef(null);
   const formFN = React.useRef(null);
   const formLN = React.useRef(null);
   const formE = React.useRef(null);
   const formP = React.useRef(null);
   const formM = React.useRef(null);

   console.log({ location });

   const hide_form = location.pathname === '/' || location.pathname.toLowerCase().indexOf('/blog') === 0 ? '' : 'hide';

   React.useEffect(() => {
      M.Sidenav.init(document.getElementById('mobile-nav'));
   }, []);

   const sendContactEmail = (f) => {
      const firstname = formFN.current.value;
      const lastname = formLN.current.value;
      const email = formE.current.value;
      const phone = formP.current.value;
      const message = formM.current.value;

      if (firstname.length < 1) {
         M.toast({ html: 'Please ensure all required fields are filled in' });
         return;
      }
      if (lastname.length < 1) {
         M.toast({ html: 'Please ensure all required fields are filled in' });
         return;
      }
      if (email.length < 1) {
         M.toast({ html: 'Please ensure all required fields are filled in' });
         return;
      }
      if (phone.length < 1) {
         M.toast({ html: 'Please ensure all required fields are filled in' });
         return;
      }

      const data = {
         firstname,
         lastname,
         email,
         phone,
         message,
      };

      Axios({
         method: 'POST',
         url: `${API_URL}webmail.cfc?method=contact`,
         headers: {
            'Content-Type': 'application/json',
         },
         data,
      })
         .then((r) => r.status === 200 && r.data)
         .then((d) =>
            M.toast({
               html: 'Thanks for contacting us, we will get back to you shortly.',
            })
         );
      // .finally((d) => window.location.reload(false));

      // M.toast({
      //    html: 'Thanks for contacting us, we will get back to you shortly.',
      // });
      formRef.current.reset();
   };

   return (
      <div
         className=""
         style={{
            position: 'relative',
            backgroundColor: '#a09468',
            marginTop: '-20px',
         }}
      >
         <div id="ellipse" className="ellipseFooter" style={{ width: '140%' }}></div>
         <div className="clearfix"></div>

         <div className="clearfix"></div>
         <div
            className="container center white-text"
            style={{
               position: 'relative',
               backgroundColor: '#a09468',
            }}
         >
            <footer id="Contact" className="row" style={{ padding: '5px 0', margin: '0' }}>
               <img src={logoWhite} />
               <br />
               <div className="col s12 m12 l8 offset-l2 center">
                  <p
                     style={{
                        fontWeight: '300',
                     }}
                  >
                     <span
                        className="robotoCondensed"
                        style={{
                           fontSize: '1.5rem',
                           lineHeight: '1',
                           textTransform: 'uppercase',
                           fontWeight: '500',
                        }}
                     >
                        FREE SHIPPING WITHIN THE US
                     </span>
                     <br />
                     10% Discount on orders of nine (9) boxes or more
                     <br />
                     Wholesale inquiries welcomed
                  </p>
               </div>

               <div className="clearfix"></div>
               <div className={hide_form}>
                  <p
                     className="robotoCondensed"
                     style={{
                        fontSize: '2.3rem',
                        lineHeight: '1',
                        textTransform: 'uppercase',
                     }}
                  >
                     Contact us with any questions
                  </p>

                  <div className="row container">
                     <form ref={formRef}>
                        <div className="col s12 m8 offset-m2 l6 offset-l3">
                           <div className="col s6 m6 l6">
                              <input ref={formFN} id="name" type="text" className="validate" placeholder="* First name" />
                           </div>
                           <div className="col s6 m6 l6">
                              <input ref={formLN} id="name" type="text" className="validate" placeholder="* Last name" />
                           </div>
                           <div className="col s6 m6 l6">
                              <input ref={formE} id="email" type="text" className="validate" placeholder="* Email address" />
                           </div>
                           <div className="col s6 m6 l6">
                              <input ref={formP} id="phone" type="text" className="validate" placeholder="* Phone number" />
                           </div>

                           <div className="col s12 m12 l12">
                              <textarea ref={formM} id="message" type="text" className="validate" placeholder="Your message ..." style={{ height: '50px', paddingTop: '10px' }}></textarea>
                           </div>
                           <button type="button" className="redBtn" onClick={sendContactEmail}>
                              SUBMIT
                           </button>
                        </div>
                     </form>
                  </div>
               </div>

               <div className="clearfix"></div>
               <br />
               <p
                  style={{
                     textTransform: 'uppercase',
                     fontSize: '14px',
                     marginTop: '25px',
                  }}
               >
                  &copy; 2020 STELLAR TOFFEE | Crafted and Hosted by{' '}
                  <a href="https://thesabresolution.com/" rel="noopener noreferrer" target="_blank" className="white-text">
                     Sabre Creative
                  </a>
               </p>
            </footer>
         </div>
      </div>
   );
};

export default Home;
