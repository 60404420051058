import React, { useContext } from 'react';
import { CartContext } from '../../context/CartContext';

const Product = (props) => {
   const { itemCount } = useContext(CartContext);
   const URL = 'https://stellartoffee.com';
   return (
      <div onClick={() => props.trigger(props.id)} className={props.cl}>
         <figure className="effect-bubba">
            <p>
               <i className="fas fa-tag"></i>
               <span>${props.price}</span>
            </p>
            <img src={`${URL}/assets/products/large/${props.image}`} alt="img02" style={{ width: '100%' }} />
            <figcaption className="">
               <div className="">
                  <h3 style={{ marginBottom: '0', paddingBottom: '0' }} className="prtTitle robotoSlab">
                     {props.name}
                  </h3>
               </div>
            </figcaption>
         </figure>
         <div className="center-align" style={{ marginTop: '10px' }}>
            <span style={{ backgroundColor: '#c41c1c' }} className="btn white-text">
               {itemCount > 0 ? 'Add To Order' : 'Buy Now'}
            </span>
         </div>
      </div>
   );
};

export default Product;
