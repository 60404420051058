import React, { useState, useEffect } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useForm } from 'react-hook-form';
import M from 'materialize-css';
import PaypalButton from './paypal_button';
import CryptoJS from 'crypto-js';
import hmacSHA1 from 'crypto-js/sha1';
import Base64 from 'crypto-js/enc-base64';
import moment from 'moment';
import Axios from 'axios';
import creditcards from './credit-cards.png';
import Confirmation from '../CartPage/cart.confirmation.paypal';

let url = window.location.href;
let arr = url.split('/');
let arr2 = arr[2].split(':');
const URL = arr[0] + '//' + arr2[0] + '/cfc/api/';

//PAYPAL U : sb-1c3h13946610@personal.example.com
//PAYPAL P : *9Ah1R%k

const CLIENT = {
   sandbox: 'AfyYYswWAFV9yOafrefQnllVfWuX7WwdrkTau3-3IznNidFYutjvYgiwhIDptG6N7PuOg-owzUuMieGR',
   production: 'AbjRZUyXWkaRUyD_NGo0s1CznLsqgxYci30WP-gQYQck0GDF7Js3FKFINSpuT1O_VkzNvFi4pj2ydjOc',
};

const ENV = process.env.NODE_ENV === 'production' ? 'production' : 'sandbox';
// const ENV = 'sandbox';

// const perBoxPrice = 15;
// 10% discound on >=9
const perBoxPrice = 15;

const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const year = new Date().getFullYear();
const years = Array.from(new Array(15), (val, i) => i + year);
const round = (number, decimalPlaces) =>
   Number(Math.round(number + 'e' + decimalPlaces) + 'e-' + decimalPlaces);
const currencyAttr = {
   decimalScale: 2,
   fixedDecimalScale: true,
   displayType: 'text',
   thousandSeparator: true,
   prefix: '$',
};

const currencyAttrNoDollar = {
   decimalScale: 2,
   fixedDecimalScale: true,
   displayType: 'text',
   thousandSeparator: true,
   prefix: '$',
};

const states = [
   { label: 'Alabama', value: 'AL' },
   { label: 'Alaska', value: 'AK' },
   // { label: 'American Samoa', value: 'AS' },
   { label: 'Arizona', value: 'AZ' },
   { label: 'Arkansas', value: 'AR' },
   { label: 'California', value: 'CA' },
   { label: 'Colorado', value: 'CO' },
   { label: 'Connecticut', value: 'CT' },
   { label: 'Delaware', value: 'DE' },
   // { label: 'District of Columbia', value: 'DC' },
   // { label: 'States of Micronesia', value: 'FM' },
   { label: 'Florida', value: 'FL' },
   { label: 'Georgia', value: 'GA' },
   // { label: 'Guam', value: 'GU' },
   { label: 'Hawaii', value: 'HI' },
   { label: 'Idaho', value: 'ID' },
   { label: 'Illinois', value: 'IL' },
   { label: 'Indiana', value: 'IN' },
   { label: 'Iowa', value: 'IA' },
   { label: 'Kansas', value: 'KS' },
   { label: 'Kentucky', value: 'KY' },
   { label: 'Louisiana', value: 'LA' },
   { label: 'Maine', value: 'ME' },
   // { label: 'Marshall Islands', value: 'MH' },
   { label: 'Maryland', value: 'MD' },
   { label: 'Massachusetts', value: 'MA' },
   { label: 'Michigan', value: 'MI' },
   { label: 'Minnesota', value: 'MN' },
   { label: 'Mississippi', value: 'MS' },
   { label: 'Missouri', value: 'MO' },
   { label: 'Montana', value: 'MT' },
   { label: 'Nebraska', value: 'NE' },
   { label: 'Nevada', value: 'NV' },
   { label: 'New Hampshire', value: 'NH' },
   { label: 'New Jersey', value: 'NJ' },
   { label: 'New Mexico', value: 'NM' },
   { label: 'New York', value: 'NY' },
   { label: 'North Carolina', value: 'NC' },
   { label: 'North Dakota', value: 'ND' },
   // { label: 'Northern Mariana Islands', value: 'MP' },
   { label: 'Ohio', value: 'OH' },
   { label: 'Oklahoma', value: 'OK' },
   { label: 'Oregan', value: 'OR' },
   // { label: 'Palau', value: 'PW' },
   { label: 'Pennsilvania', value: 'PA' },
   // { label: 'Puerto Rico', value: 'PR' },
   { label: 'Rhode Island', value: 'RI' },
   { label: 'South Carolina', value: 'SC' },
   { label: 'South Dakota', value: 'SD' },
   { label: 'Tennessee', value: 'TN' },
   { label: 'Texas', value: 'TX' },
   { label: 'Utah', value: 'UT' },
   { label: 'Vermont', value: 'VT' },
   { label: 'Virgin Islands', value: 'VI' },
   { label: 'Virginia', value: 'VA' },
   { label: 'Washington', value: 'WA' },
   { label: 'West Virginia', value: 'WV' },
   { label: 'Wisconsin', value: 'WI' },
   { label: 'Wyoming', value: 'WY' },
];
const helperText = { height: '18px' };

const initState = {
   order: {
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      message: '',
      amount: 0,
   },
   billing: {
      company: '',
      firstname: '',
      lastname: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      country: 'US',
   },
   shipping: {
      firstname: '',
      lastname: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      country: 'US',
      sameAsBilling: false,
   },
   payment: {
      paymentType: '',
      cc: '',
      month: '',
      year: '',
      cvv: '',
   },
};

const Form = ({ onFinalize }) => {
   const [form, setForm] = useState(initState);
   const [instances, setInstances] = useState([]);

   const [state, setState] = useState('form');

   const [orderValidate, setOrderValidate] = useState(0);
   const [billingValidate, setBillingValidate] = useState(0);
   const [shippingValidate, setShippingValidate] = useState(0);
   const [formsValid, setFormsValid] = useState({
      order: false,
      billing: false,
      shipping: false,
      payment: false,
   });
   const [goToReview, setGoToReview] = useState(false);
   const [payeezy, setPayeezy] = useState(null);
   const [orderSuccess, setOrderSuccess] = useState(null);

   const setIsValid = (valid, field) => {
      setFormsValid({ ...formsValid, [field]: valid });
   };

   const updateForm = (values, field) => {
      setForm({ ...form, [field]: values });
   };

   useEffect(() => {
      const setupInstance = () => {
         if (state === 'form') {
            const elems = document.querySelectorAll('.collapsible');
            const _instances = M.Collapsible.init(elems, {});
            _instances[0].open(0);
            setInstances(_instances);
         }
      };

      setupInstance();
   }, []);

   useEffect(() => {
      if (state === 'payment') {
         setOrderValidate(orderValidate + 1);
      }
   }, [state]);

   const payeezyRun = () => {
      const login = 'WSP-TOFFE-wYFHSQDY6w';
      const key = 'A9_l9srG82WbdS9vQUED';
      const timestamp = moment().unix();
      const amount = parseInt(form.order.amount) * perBoxPrice;
      const currency_code = 'USD';
      const line_items = '';

      const max = 999999999;
      const sequence = Math.floor(Math.random() * max) + 1;
      const invoice = '000001';

      const hmac_data = `${login}^${sequence}^${timestamp}^${amount}^${currency_code}`;

      const hash = CryptoJS.HmacSHA1(hmac_data, key);
      const digest_hash = Base64.stringify(hmacSHA1(hash));

      Axios({
         method: 'POST',
         url: `${URL}orders.cfc?method=peinit`,
         data: {
            data: form,
            cart: { ...form.order },
            payeezy: {
               timestamp,
               login,
               amount,
               sequence,
            },
         },
      })
         .then((resp) => resp.status === 200 && resp.data)
         .then((data) => {
            if (data.success) {
               setPayeezy({
                  timestamp,
                  login,
                  amount,
                  sequence,
                  hash: data.payeezy.hash,
                  id: data.order.orderId,
               });
            }
         });
   };

   const openContent = (x) => {
      instances[0] && instances[0].open(x);
   };

   const finalizeForm = () => {
      // setOrderValidate(orderValidate + 1);
      setState('review');
      console.log();
   };

   const onPaypalSuccess = (x) => {
      console.log({ x });
      /* 
      {
         cancelled : false,
         paid : true,
         payerId: xxx
         papymentId: xxx
         paymentToken: xxx
         returnUrl
      }
      */

      x.cancelled === false &&
         x.paid === true &&
         Axios({
            method: 'POST',
            url: `${URL}orders.cfc?method=pppurchase`,
            data: {
               data: form,
               cart: { ...form.order },
               x: x,
            },
         })
            .then((resp) => resp.status === 200 && resp.data)
            .then((data) => {
               if (data.success) {
                  setForm({
                     ...form,
                     order: { ...form.order, orderId: data.orderId },
                  });
                  setState('ppsuccess');
               }
            });
   };
   const onPaypalError = () => {};
   const onPaypalCancel = () => {};

   console.log({ form });

   return (
      <div
         className="card goldBg z-depth-4"
         style={state === 'review' ? { padding: '0 25px' } : {}}
      >
         <p
            className="robotoSlab white-text"
            style={{
               fontSize: '2rem',
               lineHeight: '1',
               margin: '1rem 0 0',
               paddingTop: '30px',
            }}
         >
            START YOUR COUNTDOWN
         </p>
         <p
            className="white-text"
            style={{
               fontSize: '1.5rem',
               lineHeight: '1.2',
               fontWeight: '300',
               marginTop: '0',
            }}
         >
            {state === 'form' && 'PLACE YOUR ORDER'}
            {state === 'review' && 'LAUNCH YOUR ORDER'}
         </p>
         {state === 'form' && (
            <>
               <ul className="collapsible noBgCollapsable" style={{ margin: '20px 25px' }}>
                  <li id="orderInfo">
                     <div className="collapsible-header noBgCollapsableHead">
                        Order Information
                        <i className="material-icons right">add</i>
                     </div>

                     <div
                        className="collapsible-body noBgCollapsableBody"
                        style={{ marginBottom: '0' }}
                     >
                        <OrderInformation
                           validate={orderValidate}
                           isValid={setIsValid}
                           update={updateForm}
                           setActive={openContent}
                        />
                     </div>
                  </li>

                  <li id="billInfo">
                     <div className="collapsible-header noBgCollapsableHead">
                        Billing Information
                        <i className="material-icons right">add</i>
                     </div>

                     <div
                        className="collapsible-body noBgCollapsableBody"
                        style={{ marginBottom: '0' }}
                     >
                        <BillingInformation
                           validate={billingValidate}
                           isValid={setIsValid}
                           update={updateForm}
                           setActive={openContent}
                           order={form}
                        />
                     </div>
                  </li>

                  <li id="shipInfo">
                     <div className="collapsible-header noBgCollapsableHead">
                        Shipping Information
                        <i className="material-icons right">add</i>
                     </div>

                     <div
                        className="collapsible-body noBgCollapsableBody"
                        style={{ marginBottom: '0' }}
                     >
                        <ShippingInformation
                           finalizeForm={finalizeForm}
                           validate={shippingValidate}
                           isValid={setIsValid}
                           update={updateForm}
                           billing={form.billing}
                           setActive={openContent}
                        />
                     </div>
                  </li>

                  {/* <li id="payInfo">
                     <div className="collapsible-header noBgCollapsableHead">
                        Payment Information
                        <i className="material-icons right">add</i>
                     </div>

                     <div className="collapsible-body noBgCollapsableBody" style={{ marginBottom: '0' }}>
                        <PaymentInformation finalizeForm={finalizeForm} data={form} validate={shippingValidate} isValid={setIsValid} update={updateForm} setActive={openContent} />
                     </div>
                  </li> */}
               </ul>
            </>
         )}

         {state === 'ppsuccess' && <Confirmation order={form} />}

         {state === 'review' && (
            <>
               <div
                  className="valign-wrapper left-align white-text"
                  style={{ borderBottom: '1px solid #dddddd' }}
               >
                  <div className="col s5">
                     <p>ORDER INFO:</p>
                  </div>
                  <div className="col s7">
                     <p style={{ fontWeight: '300' }}>
                        {form.order.firstname} {form.order.lastname}
                        <br />
                        {form.order.email}
                        <br />
                        {form.order.phone}
                        <br />
                        Box(es) order: {form.order.amount}
                        <br />
                        {parseInt(form.order.amount) > 8 ? (
                           <>
                              Total:{' '}
                              <CurrencyFormat
                                 {...currencyAttr}
                                 value={round(perBoxPrice * form.order.amount * 0.9, 2)}
                              />
                           </>
                        ) : (
                           <>
                              Total:{' '}
                              <CurrencyFormat
                                 {...currencyAttr}
                                 value={round(perBoxPrice * form.order.amount, 2)}
                              />
                           </>
                        )}
                     </p>
                  </div>
               </div>

               <div
                  className="valign-wrapper left-align white-text"
                  style={{ borderBottom: '1px solid #dddddd' }}
               >
                  <div className="col s5">
                     <p>BILLING INFO:</p>
                  </div>
                  <div className="col s7">
                     <p style={{ fontWeight: '300' }}>
                        {form.billing.firstname} {form.billing.lastname}
                        <br />
                        {form.billing.address1}
                        {form.billing.address2.length > 0 && (
                           <>
                              <br />
                              {form.billing.address2}
                           </>
                        )}
                        <br />
                        {form.billing.city} {form.billing.state}
                        <br />
                        {form.billing.zip} {form.billing.country}
                     </p>
                  </div>
               </div>

               <div
                  className="valign-wrapper left-align white-text"
                  style={{ borderBottom: '1px solid #dddddd' }}
               >
                  <div className="col s5">
                     <p>SHIPPING INFO:</p>
                  </div>
                  <div className="col s7" style={{ fontWeight: '300' }}>
                     {/* <p>Same as Billing Address</p> */}
                     <p style={{ fontWeight: '300' }}>
                        {form.shipping.firstname} {form.shipping.lastname}
                        <br />
                        {form.shipping.address1}
                        {form.shipping.address2.length > 0 && (
                           <>
                              <br />
                              {form.shipping.address2}
                           </>
                        )}
                        <br />
                        {form.shipping.city} {form.shipping.state}
                        <br />
                        {form.shipping.zip} {form.shipping.country}
                     </p>
                  </div>
               </div>

               <div className="clearfix"></div>
               <br />

               <div className="row white">
                  <div className="row white" style={{ margin: '0' }}>
                     <div className="col s12">
                        <p
                           className="center-align"
                           style={{ fontWeight: 'bold', fontSize: '1.2em' }}
                        >
                           PAYMENT METHOD
                        </p>
                        <p>
                           Please select your purchase method below, you will then be directed to a
                           payment page to fill out your information to complete payment.
                        </p>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col s12 valign-wrapper center-align">
                        <div className="center-align" style={{ width: '100%', margin: '10px 0' }}>
                           {form.order.amount > 8 ? (
                              <>
                                 <PaypalButton
                                    client={CLIENT}
                                    env={ENV}
                                    commit={true}
                                    currency={'USD'}
                                    total={round(perBoxPrice * form.order.amount * 0.9, 2)}
                                    onSuccess={onPaypalSuccess}
                                    onError={onPaypalError}
                                    onCancel={onPaypalCancel}
                                 />
                              </>
                           ) : (
                              <>
                                 <PaypalButton
                                    client={CLIENT}
                                    env={ENV}
                                    commit={true}
                                    currency={'USD'}
                                    total={round(perBoxPrice * form.order.amount, 2)}
                                    onSuccess={onPaypalSuccess}
                                    onError={onPaypalError}
                                    onCancel={onPaypalCancel}
                                 />
                              </>
                           )}
                        </div>
                     </div>
                     <div className="col s12 valign-wrapper center-align">
                        <div className="center-align" style={{ width: '100%', margin: '10px 0' }}>
                           <div style={{ cursor: 'pointer' }} onClick={() => payeezyRun()}>
                              <img src={creditcards} style={{ height: '40px' }} alt="" />
                           </div>

                           {/* <button onClick={() => payeezyRun()} className="redBtn">
                              LAUNCH
                           </button> */}
                           {payeezy && <PayeezyForm {...payeezy} order={form} />}
                        </div>
                     </div>
                  </div>
               </div>

               {/* <button onClick={() => onPaypalSuccess({ cancelled: false, paid: true })}>fake submit</button> */}
               <br />
               <br />
            </>
         )}
      </div>
   );
};

const OrderInformation = ({ validate, isValid, update, setActive }) => {
   const { handleSubmit, watch, register, errors, trigger } = useForm({
      mode: 'onBlur',
      defaultValues: initState.order,
   });

   const [form, setForm] = useState(initState.order);
   useEffect(() => {
      const updateForm = () => {
         update(form, 'order');
         console.log('update parent form');
      };

      updateForm();
      return () => {};
   }, [form]);

   useEffect(() => {
      validate > 0 && trigger().then((resp) => isValid(resp, 'order'));
   }, [validate]);

   const goNext = (next) => {
      trigger().then((res) => {
         if (res) {
            if (form.amount <= 0) {
               M.toast({ html: 'How many Boxes would you like?' });
               return;
            }
            update(form, 'order');

            setActive(next);
         } else {
            M.toast({
               html: 'Not all fields are filled in under order information.',
            });
         }
      });

      return;
   };

   const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

   return (
      <>
         <div className="input-field col s6" style={{ margin: '0' }}>
            <small style={helperText} className="red-text helper-text">
               {errors.firstname && errors.firstname.message}
            </small>
            <input
               ref={register({ required: 'First name is required' })}
               name="firstname"
               type="text"
               placeholder="* First name"
               onChange={(e) => setForm({ ...form, firstname: e.target.value })}
            />
         </div>
         <div className="input-field col s6" style={{ margin: '0' }}>
            <small style={helperText} className="red-text helper-text">
               {errors.lastname && errors.lastname.message}
            </small>
            <input
               ref={register({ required: 'Last name is required' })}
               name="lastname"
               type="text"
               placeholder="* Last name"
               onChange={(e) => setForm({ ...form, lastname: e.target.value })}
            />
         </div>
         <div className="input-field col s6" style={{ margin: '0' }}>
            <small style={helperText} className="red-text helper-text">
               {errors.email && errors.email.message}
            </small>
            <input
               ref={register({
                  required: 'Email is required',
                  pattern: {
                     value: re,
                     message: 'Entered email is not formated correctly',
                  },
               })}
               name="email"
               type="text"
               placeholder="* Email address"
               onChange={(e) => setForm({ ...form, email: e.target.value })}
            />
         </div>
         <div className="input-field col s6" style={{ margin: '0' }}>
            <small style={helperText} className="red-text helper-text">
               {errors.phone && errors.phone.message}
            </small>
            <input
               ref={register}
               name="phone"
               type="text"
               placeholder="Phone number"
               onChange={(e) => setForm({ ...form, phone: e.target.value })}
            />
         </div>

         <div className="input-field col s12" style={{ marginBottom: '0' }}>
            <small style={helperText} className="red-text helper-text">
               {errors.message && errors.message.message}
            </small>
            <textarea
               onChange={(e) => setForm({ ...form, message: e.target.value })}
               className="materialize-textarea white"
               style={{ paddingLeft: '10px', paddingRight: '10px' }}
               ref={register}
               name="message"
               id="message"
            ></textarea>
            <label style={{ left: '1rem' }} htmlFor="message" className="active">
               Gift Message
            </label>
         </div>

         <div className="input-field col s6" style={{ marginTop: '0' }}>
            <small style={helperText} className="red-text helper-text">
               {errors.amount && errors.amount.message}
            </small>
            <select
               defaultValue={0}
               ref={register({ required: 'Amount is required' })}
               name="amount"
               className="browser-default"
               onChange={(e) => setForm({ ...form, amount: e.target.value })}
            >
               <option value={0} disabled>
                  Quantity
               </option>
               {[...Array(25)].map((x, i) => (
                  <option key={i + 1}>{i + 1}</option>
               ))}
            </select>
         </div>

         <div className="col s6 m6 l6 white-text">
            <p>
               {parseInt(form.amount) > 8 ? (
                  <>
                     <b>Total:</b>{' '}
                     <CurrencyFormat
                        {...currencyAttr}
                        value={round(perBoxPrice * form.amount * 0.9, 2)}
                     />
                  </>
               ) : (
                  <>
                     <b>Total:</b>{' '}
                     <CurrencyFormat
                        {...currencyAttr}
                        value={round(perBoxPrice * form.amount, 2)}
                     />
                  </>
               )}
            </p>
         </div>

         <div className="col s12 white-text">
            <button className="btn" onClick={() => goNext(1)}>
               Next
            </button>
         </div>
         <br />

         <div className="clearfix"></div>
      </>
   );
};

const BillingInformation = ({ validate, isValid, update, setActive, order }) => {
   const { register, setValue, getValues, errors, trigger } = useForm({
      mode: 'onBlur',
      defaultValues: initState.billing,
   });

   const [form, setForm] = useState({ ...initState.billing });
   useEffect(() => {
      const updateForm = () => {
         update(form, 'billing');
         console.log('update parent form');
      };

      updateForm();
      return () => {};
   }, [form]);

   useEffect(() => {
      validate > 0 && trigger().then((resp) => isValid(resp, 'billing'));
   }, [validate]);

   useEffect(() => {
      setValue('firstname', order.order.firstname);
      setForm({ ...form, firstname: order.order.firstname });
   }, [order.order.firstname]);

   useEffect(() => {
      setValue('lastname', order.order.lastname);
      setForm({ ...form, lastname: order.order.lastname });
   }, [order.order.lastname]);

   const goNext = (next) => {
      trigger().then((res) => {
         if (res) {
            if (form.amount <= 0) {
               M.toast({ html: 'How many Boxes would you like?' });
               return;
            }
            setActive(next);
         } else {
            M.toast({
               html: 'Not all fields are filled in under billing information.',
            });
         }
      });

      return;
   };

   return (
      <>
         <div className="input-field col s12" style={{ margin: '0' }}>
            {/* <small style={helperText} className="red-text helper-text">
					{errors.company && errors.company.message}
				</small> */}
            <input
               // ref={register({ required: "Company Name is required" })}
               name="company"
               type="text"
               placeholder="Company Name (If ordering for a company)"
               onChange={(e) => setForm({ ...form, company: e.target.value })}
            />
         </div>
         <div className="input-field col s6" style={{ margin: '0' }}>
            <small style={helperText} className="red-text helper-text">
               {errors.firstname && errors.firstname.message}
            </small>
            <input
               ref={register({ required: 'First Name is required' })}
               name="firstname"
               type="text"
               placeholder="* First Name"
               onChange={(e) => setForm({ ...form, firstname: e.target.value })}
            />
         </div>
         <div className="input-field col s6" style={{ margin: '0' }}>
            <small style={helperText} className="red-text helper-text">
               {errors.lastname && errors.lastname.message}
            </small>
            <input
               ref={register({ required: 'Last name is required' })}
               name="lastname"
               type="text"
               placeholder="* Last name"
               onChange={(e) => setForm({ ...form, lastname: e.target.value })}
            />
         </div>
         <div className="input-field col s12" style={{ margin: '0' }}>
            <small style={helperText} className="red-text helper-text">
               {errors.address1 && errors.address1.message}
            </small>
            <input
               ref={register({ required: 'Address 1 is required' })}
               name="address1"
               type="text"
               placeholder="* Address 1"
               onChange={(e) => setForm({ ...form, address1: e.target.value })}
            />
         </div>
         <div className="input-field col s12" style={{ margin: '0' }}>
            <input
               ref={register}
               name="address2"
               type="text"
               placeholder="Address 2 (OPTIONAL) Apartment, suite, floor, etc."
               onChange={(e) => setForm({ ...form, address2: e.target.value })}
            />
         </div>
         <div className="input-field col s6" style={{ margin: '0' }}>
            <small style={helperText} className="red-text helper-text">
               {errors.city && errors.city.message}
            </small>
            <input
               ref={register({ required: 'City is required' })}
               name="city"
               type="text"
               placeholder="* City"
               onChange={(e) => setForm({ ...form, city: e.target.value })}
            />
         </div>

         <div className="input-field col s6" style={{ margin: '0' }}>
            <small style={helperText} className="red-text helper-text">
               {errors.state && errors.state.message}
            </small>
            <select
               defaultValue=""
               ref={register({ required: 'State is required' })}
               name="state"
               className="browser-default"
               onChange={(e) => setForm({ ...form, state: e.target.value })}
            >
               <option value="" disabled>
                  Choose your State
               </option>
               {states &&
                  states.map((state, i) => (
                     <option key={i} value={state.value}>
                        {state.label}
                     </option>
                  ))}
            </select>
         </div>

         <div className="clearfix"></div>

         <div className="input-field col s6" style={{ margin: '0' }}>
            <small style={helperText} className="red-text helper-text">
               {errors.zip && errors.zip.message}
            </small>
            <input
               ref={register({ required: 'Zip is required' })}
               name="zip"
               type="text"
               placeholder="* Zip/Postal Code"
               onChange={(e) => setForm({ ...form, zip: e.target.value })}
            />
         </div>
         {/* <div className="input-field col s6 hide" style={{ margin: '0' }}>
            <small style={helperText} className="red-text helper-text">
               {errors.country && errors.country.message}
            </small>
            <select defaultValue="" ref={register({ required: 'Country is required' })} name="country" className="browser-default" onChange={(e) => setForm({ ...form, country: e.target.value })}>
               <option value="" disabled>
                  Choose your Country
               </option>
               <option value="USA">United States</option>
               <option value="Can">Canada</option>
            </select>
         </div> */}

         <div className="clearfix"></div>

         <div>
            <button className="btn" onClick={() => goNext(2)}>
               Next
            </button>
         </div>
         <br />

         <div className="clearfix"></div>
      </>
   );
};

const ShippingInformation = ({ finalizeForm, validate, isValid, update, billing, setActive }) => {
   const { handleSubmit, watch, register, errors, setValue, trigger } = useForm({
      mode: 'onBlur',
      defaultValues: initState.shipping,
   });

   const [form, setForm] = useState(initState.shipping);
   const [sameAsBilling, setSameAsBilling] = useState(false);
   useEffect(() => {
      const updateForm = () => {
         update(form, 'shipping');
         console.log('update parent form');
      };

      updateForm();
      return () => {};
   }, [form]);

   useEffect(() => {
      validate > 0 && trigger().then((resp) => isValid(resp, 'shipping'));
   }, [validate]);

   const toggleSameAsBilling = (v) => {
      if (v) {
         setSameAsBilling(v);
      }
   };

   console.log('billing', { billing });

   useEffect(() => {
      if (sameAsBilling) {
         setForm(billing);
         setValue('firstname', billing.firstname);
         setValue('lastname', billing.lastname);
         setValue('address1', billing.address1);
         setValue('address2', billing.address2);
         setValue('city', billing.city);
         setValue('state', billing.state);
         setValue('zip', billing.zip);
         setValue('country', billing.country);
      }
   }, [sameAsBilling]);

   const goNext = (next) => {
      trigger().then((res) => {
         if (res) {
            if (form.amount <= 0) {
               M.toast({ html: 'How many Boxes would you like?' });
               return;
            }
            setActive(next);
         } else {
            M.toast({
               html: 'Not all fields are filled in under billing information.',
            });
         }
      });

      return;
   };

   return (
      <>
         <div className="row" style={{ margin: '0', height: '40px', textAlign: 'left' }}>
            <div className="col s12 white-text" style={{ marginBottom: '0' }}>
               <label htmlFor="sameAsBilling">
                  <input
                     ref={register()}
                     name="sameAsBilling"
                     id="sameAsBilling"
                     type="checkbox"
                     onChange={(e) => toggleSameAsBilling(e.target.checked)}
                  />
                  <span className="white-text">
                     Billing Address is same as the Shipping Address
                  </span>
               </label>
            </div>
         </div>
         <div className="clearfix"></div>

         <div className="input-field col s6" style={{ margin: '0' }}>
            <small style={helperText} className="red-text helper-text">
               {errors.firstname && errors.firstname.message}
            </small>
            <input
               ref={register({ required: 'First Name is required' })}
               name="firstname"
               type="text"
               placeholder="* First Name"
               onChange={(e) => setForm({ ...form, firstname: e.target.value })}
            />
         </div>
         <div className="input-field col s6" style={{ margin: '0' }}>
            <small style={helperText} className="red-text helper-text">
               {errors.lastname && errors.lastname.message}
            </small>
            <input
               ref={register({ required: 'Last name is required' })}
               name="lastname"
               type="text"
               placeholder="* Last name"
               onChange={(e) => setForm({ ...form, lastname: e.target.value })}
            />
         </div>
         <div className="input-field col s12" style={{ margin: '0' }}>
            <small style={helperText} className="red-text helper-text">
               {errors.address1 && errors.address1.message}
            </small>
            <input
               ref={register({ required: 'Address 1 is required' })}
               name="address1"
               type="text"
               placeholder="* Address 1"
               onChange={(e) => setForm({ ...form, address1: e.target.value })}
            />
         </div>
         <div className="input-field col s12" style={{ margin: '0' }}>
            <input
               ref={register}
               name="address2"
               type="text"
               placeholder="Address 2 (OPTIONAL) Apartment, suite, floor, etc."
               onChange={(e) => setForm({ ...form, address2: e.target.value })}
            />
         </div>
         <div className="input-field col s6" style={{ margin: '0' }}>
            <small style={helperText} className="red-text helper-text">
               {errors.city && errors.city.message}
            </small>
            <input
               ref={register({ required: 'City is required' })}
               name="city"
               type="text"
               placeholder="* City"
               onChange={(e) => setForm({ ...form, city: e.target.value })}
            />
         </div>

         <div className="input-field col s6" style={{ margin: '0' }}>
            <small style={helperText} className="red-text helper-text">
               {errors.state && errors.state.message}
            </small>
            <select
               defaultValue=""
               ref={register({ required: 'State is required' })}
               name="state"
               className="browser-default"
               onChange={(e) => setForm({ ...form, state: e.target.value })}
            >
               <option value="" disabled>
                  Choose your State
               </option>
               {states &&
                  states.map((state, i) => (
                     <option key={i} value={state.value}>
                        {state.label}
                     </option>
                  ))}
            </select>
         </div>

         <div className="clearfix"></div>

         <div className="input-field col s6" style={{ margin: '0' }}>
            <small style={helperText} className="red-text helper-text">
               {errors.zip && errors.zip.message}
            </small>
            <input
               ref={register({ required: 'Zip is required' })}
               name="zip"
               type="text"
               placeholder="* Zip/Postal Code"
               onChange={(e) => setForm({ ...form, zip: e.target.value })}
            />
         </div>
         {/* <div className="input-field col s6 hide" style={{ margin: '0' }}>
            <small style={helperText} className="red-text helper-text">
               {errors.country && errors.country.message}
            </small>
            <select defaultValue="" ref={register({ required: 'Country is required' })} name="country" className="browser-default" onChange={(e) => setForm({ ...form, country: e.target.value })}>
               <option value="" disabled>
                  Choose your Country
               </option>
               <option value="USA">United States</option>
               <option value="Can">Canada</option> 
            </select>
         </div> */}

         <div className="clearfix"></div>

         <div>
            <button
               onClick={() => finalizeForm()}
               className="redBtn"
               style={{ lineHeight: '21px' }}
            >
               REVIEW
            </button>
            {/* <button className="btn" onClick={() => goNext(3)}>
               Next
            </button> */}
         </div>
         <br />

         <div className="clearfix"></div>
      </>
   );
};

const PaymentInformation = ({ data, finalizeForm, update }) => {
   const [paymentType, setPaymentType] = useState('');
   const [readyToReview, setReadyToReview] = useState(false);
   const [errors, setErrors] = useState([]);

   useEffect(() => {
      const updateForm = () => {
         update({ ...data.payment, paymentType }, 'payment');
         console.log('update parent form');
      };

      updateForm();
      return () => {};
   }, [paymentType]);

   const selectedItem = { background: '#653800' };
   const nonSelectedItem = { background: 'rgb(160, 148, 104)' };

   useEffect(() => {
      const reviewData = (d) => {
         setErrors([]);
         setReadyToReview(false);

         if (data.order.firstname.length === 0)
            setErrors(errors.concat(['Order First Name must be completed']));
         if (errors.length === 0 && data.order.lastname.length === 0)
            setErrors(errors.concat(['Order Last Name must be completed']));
         if (errors.length === 0 && data.order.email.length === 0)
            setErrors(errors.concat(['Order Email must be completed']));
         // if (errors.length === 0 && data.order.phone.length === 0) setErrors(errors.concat(['Order Phone must be completed']));
         if (errors.length === 0 && parseInt(data.order.amount) < 1)
            setErrors(errors.concat(['Order Amount must be greater then 0']));

         if (errors.length === 0 && data.billing.firstname.length === 0)
            setErrors(errors.concat(['Billing First Name must be completed']));
         if (errors.length === 0 && data.billing.lastname.length === 0)
            setErrors(errors.concat(['Billing Last Name must be completed']));
         if (errors.length === 0 && data.billing.address1.length === 0)
            setErrors(errors.concat(['Billing Address1 must be completed']));
         if (errors.length === 0 && data.billing.city.length === 0)
            setErrors(errors.concat(['Billing City must be completed']));
         if (errors.length === 0 && data.billing.state.length === 0)
            setErrors(errors.concat(['Billing State must be completed']));
         if (errors.length === 0 && data.billing.zip.length === 0)
            setErrors(errors.concat(['Billing Zip must be completed']));
         if (errors.length === 0 && data.billing.country.length === 0)
            setErrors(errors.concat(['Billing Country must be completed']));

         if (errors.length === 0 && data.shipping.firstname.length === 0)
            setErrors(errors.concat(['Shipping First Name must be completed']));
         if (errors.length === 0 && data.shipping.lastname.length === 0)
            setErrors(errors.concat(['Shipping Last Name must be completed']));
         if (errors.length === 0 && data.shipping.address1.length === 0)
            setErrors(errors.concat(['Shipping Address1 must be completed']));
         if (errors.length === 0 && data.shipping.city.length === 0)
            setErrors(errors.concat(['Shipping City must be completed']));
         if (errors.length === 0 && data.shipping.state.length === 0)
            setErrors(errors.concat(['Shipping State must be completed']));
         if (errors.length === 0 && data.shipping.zip.length === 0)
            setErrors(errors.concat(['Shipping Zip must be completed']));
         if (errors.length === 0 && data.shipping.country.length === 0)
            setErrors(errors.concat(['Shipping Country must be completed']));

         // if (errors.length === 0 && data.payment.paymentType.length === 0) setErrors(errors.concat(['Please select a payment method']));

         return () => setErrors([]);
      };

      reviewData(data);
   }, [data]);

   useEffect(() => (errors.length === 0 ? setReadyToReview(true) : setReadyToReview(false)), [
      errors,
   ]);

   return (
      <>
         <p className="center-align white-text">Select your preferred payment method</p>
         <div className="collection">
            <div
               onClick={() => setPaymentType('paypal')}
               className={`collection-item ${paymentType === 'paypal' && 'active'}`}
               style={paymentType === 'paypal' ? selectedItem : nonSelectedItem}
            >
               <img src="/assets/images/paypal-button-2x.png" style={{ height: '40px' }} alt="" />
            </div>
            <div
               onClick={() => setPaymentType('card')}
               className={`collection-item ${paymentType === 'card' && 'active'}`}
               style={paymentType === 'card' ? selectedItem : nonSelectedItem}
            >
               <img src={creditcards} style={{ height: '40px' }} alt="" />
            </div>
         </div>

         {readyToReview === true && (
            <>
               <br />
               <div>
                  <button onClick={() => finalizeForm()} className="redBtn">
                     REVIEW
                  </button>
               </div>
               <br />
               <div className="clearfix"></div>
            </>
         )}
         {/* <PaypalButton client={CLIENT} env={ENV} commit={true} currency={'USD'} total={order.amount * perBoxPrice} onSuccess={onSuccess} onError={onError} onCancel={onCancel} /> */}
         {/* <div className="white-text">---- OR ----</div> */}
         <br />
         {/* <div className="left-align white-text" style={{ margin: '0', padding: '0 10px 10px' }}>
            All major credit cards accepted:
            <br />
         </div>
         <div className=" col s12" style={{ marginBottom: '0' }}>
            <input id="payment.cc" name="payment.cc" type="text" placeholder="Credit Card Number" />
         </div>
         <div className="left-align white-text" style={{ margin: '0', padding: '0 10px 10px' }}>
            Expiration Date
         </div>
         <div className=" col s4" style={{ marginBottom: '0' }}>
            <select className="browser-default" defaultValue="" id="payment.month" name="payment.month" type="text">
               <option value="" disabled>
                  Month
               </option>
               {months.map((el, i) => (
                  <option key={i} value={el}>
                     {el}
                  </option>
               ))}
            </select>
         </div>
         <div className=" col s4" style={{ marginBottom: '0' }}>
            <select className="browser-default" defaultValue="" id="payment.year" name="payment.year" type="text">
               <option value="" disabled>
                  Year
               </option>
               {years.map((el, i) => (
                  <option key={i} value={el}>
                     {el}
                  </option>
               ))}
            </select>
         </div>
         <div className=" col s4" style={{ marginBottom: '0' }}>
            <input id="payment.cvv" name="payment.cvv" type="text" placeholder="Security Code (CVV)" />
         </div> */}
         <div className="clearfix"></div>
      </>
   );
};

const PayeezyForm = ({ order, id, timestamp, login, amount, sequence, hash }) => {
   useEffect(() => {
      const form = document.getElementById('pay_now_form_3a4a8c5609');

      if (form) {
         // console.log({ order, timestamp, login, amount, sequence, hash });
         form.submit();
      }
   }, []);

   const lineItem = () => {
      const title = 'Stellar Toffee';
      const desc =
         'Stellar Toffee is a delicious departure from everything you thought you knew about toffee.';
      const item = [
         '001',
         title,
         desc,
         order.order.amount,
         perBoxPrice,
         'N',
         '',
         '',
         '',
         '',
         0,
         '',
         0,
         amount,
      ];

      return item.join('<|>');
   };
   return (
      <form
         action="https://checkout.globalgatewaye4.firstdata.com/pay"
         id="pay_now_form_3a4a8c5609"
         method="post"
      >
         <input type="hidden" name="x_login" value={login} />
         <input type="hidden" name="x_show_form" value="PAYMENT_FORM" />
         <input type="hidden" name="x_fp_sequence" value={sequence} />
         <input type="hidden" name="x_fp_timestamp" value={timestamp} />
         <input type="hidden" name="x_fp_hash" value={hash} />
         <input type="hidden" name="x_amount" value={amount} />
         <input type="hidden" name="x_currency_code" value="USD" />
         <input type="hidden" name="x_test_request" value="FALSE" />
         <input type="hidden" name="x_po_num" value={`${id}`} />
         <input type="hidden" name="x_first_name" value={order.billing.firstname} />
         <input type="hidden" name="x_last_name" value={order.billing.lastname} />
         <input type="hidden" name="x_address" value={order.billing.address} />
         <input type="hidden" name="x_city" value={order.billing.city} />
         <input type="hidden" name="x_state" value={order.billing.state} />
         <input type="hidden" name="x_zip" value={order.billing.zip} />
         <input type="hidden" name="x_country" value={order.billing.country} />
         <input type="hidden" name="x_phone" value={order.order.phone} />
         <input type="hidden" name="x_email" value={order.order.email} />
         <input type="hidden" name="x_line_item" value={lineItem()} />
         <input
            type="hidden"
            name="x_logo_url"
            value="https://stellartoffee.com/assets/images/logo/logo.png"
         />
         <input type="hidden" name="donation_prompt" />
         <input type="hidden" name="x_type" value="AUTH_CAPTURE" />
         <input type="hidden" name="button_code" value="Pay Now Stellar Toffee" />
      </form>
   );
};

export default Form;
