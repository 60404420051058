import React, { useEffect, useContext } from 'react';
import { CartContext } from '../../context/CartContext';
import { Link } from 'react-router-dom';

// const { itemCount } = useContext(CartContext);
function navlinks({ closeMobileNav, itemCount, ...rest }) {
   console.log({ closeMobileNav, itemCount });
   return (
      <React.Fragment>
         {/* <li>
				<a
					href="/#Launch"
					className="hvr-underline-reveal"
					onClick={() => closeMobileNav()}
				>
					LAUNCH
				</a>
			</li> */}
         {/* <hr className="fadeEnd" /> */}
         <li>
            <a href="/Blog" className="" onClick={() => closeMobileNav()}>
               BITS & PIECES
            </a>
         </li>
         <li>
            <a href="/Products" className="" onClick={() => closeMobileNav()}>
               ORDER
            </a>
         </li>
         {/* <hr className="fadeEnd" /> */}
         {/* <li>
				<a
					href="/Cart"
					className="hvr-underline-reveal"
					onClick={() => closeMobileNav()}
				>
					CART ({itemCount})
				</a>
			</li> */}
         {/* <hr className="fadeEnd" /> */}
         <li>
            <a href="/#Contact" className="" onClick={() => closeMobileNav()}>
               CONTACT
            </a>
         </li>
      </React.Fragment>
   );
}

export const NavLinksLeft = ({ closeMobileNav, ...rest }) => {
   console.log({ closeMobileNav });
   return (
      <React.Fragment>
         {/* <hr className="fadeEnd" /> */}
         {/* <li>
				<a
					href="/Blog"
					className="hvr-underline-reveal"
					onClick={() => closeMobileNav()}
				>
					BITS & PIECES
				</a>
			</li> */}
         {/* <hr className="fadeEnd" /> */}
      </React.Fragment>
   );
};

export default navlinks;
