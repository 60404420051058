import React, { useEffect } from 'react';
import M from 'materialize-css';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import star from '../../images/stellar-white-start.png';
import starW from '../../images/stellar-ww-star.png';
import productBox from '../../images/Stellar-product-box.png';
import { appendScript, removeScript } from '../../utils/scripts';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
// import creditcards from "./credit-cards.png";
import butterToffee1 from '../../images/Stellar-hero-FLAT.png';
import Form from './order';
import Review from './review';

const HomePage = () => {
   const [state, setState] = React.useState('form');
   const [form, setForm] = React.useState(null);

   const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
   const year = new Date().getFullYear();
   const years = Array.from(new Array(15), (val, i) => i + year);

   useEffect(() => {
      let elems = document.querySelectorAll('.collapsible');
      M.Collapsible.init(elems, {
         // accordion: false,
         onOpenStart: function (e) {
            e.querySelector('.material-icons').innerHTML = 'remove';
         },
         onCloseStart: function (e) {
            e.querySelector('.material-icons').innerHTML = 'add';
         },
      });
   }, []);

   useEffect(() => {
      appendScript('/assets/js/css3-animate-it.js');

      return () => removeScript('/assets/js/css3-animate-it.js');
   }, []);

   const handleFormSubmit = (values) => {
      setForm(values);
      setState('review');
   };

   const settings = {
      dots: true,
      arrows: false,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
   };

   return (
      <div className="">
         <div>
            <div style={{ paddingTop: '330px' }}>
               <div className="col s12 m12 l12 center-align" data-appear-top-offset="300">
                  <img src={butterToffee1} style={{ position: 'relative', zIndex: '100' }} alt="" className="responsive-img" />
               </div>
            </div>
         </div>
         <section id="Launch" style={{ position: 'relative' }}>
            <div id="ellipse" className="ellipseTop"></div>
            <div id="rectangle">
               <div className="row container center">
                  <p className="robotoSlab" style={{ fontSize: '5rem', lineHeight: '1', margin: '1rem 0' }}>
                     IT'S OUT OF THIS WORLD
                  </p>
                  <p
                     className="robotoCondensed"
                     style={{
                        fontSize: '2.3rem',
                        lineHeight: '1',
                        textTransform: 'uppercase',
                     }}
                  >
                     Engineered to be unlike any other toffee on the planet.
                  </p>
                  <p
                     className="col s12 m12 l8 offset-l2"
                     style={{
                        fontSize: '2rem',
                        lineHeight: '1.2',
                        fontWeight: '200',
                        marginTop: '0',
                     }}
                  >
                     <b>Stellar Toffee</b> is a delicious departure from everything you thought you knew about toffee. Truly a breakthrough in taste and texture, <b>Stellar Toffee</b> goes where no toffee has gone before.
                  </p>
                  <img className="star" src={star} style={{ position: 'absolute', left: '20%', top: '61%' }} />
                  <img
                     className="star"
                     src={star}
                     style={{
                        position: 'absolute',
                        right: '30%',
                        top: '61%',
                        width: '40px',
                        transform: 'rotate(20deg)',
                     }}
                  />
                  <img
                     className="star"
                     src={star}
                     style={{
                        position: 'absolute',
                        right: '15%',
                        top: '40%',
                        transform: 'rotate(20deg)',
                     }}
                  />
               </div>
            </div>
            <div id="ellipse" className="ellipseBtm"></div>
         </section>

         <div className="col s12 m6 l6 padAdj center-align animatedParent" style={{ marginTop: '-240px' }}>
            <img src={productBox} alt="" className="responsive-img animated fadeInUpShort" />
         </div>

         <div>
            <div className="row container center" style={{ position: 'relative', zIndex: '100' }}>
               <p
                  className="robotoSlab"
                  style={{
                     fontSize: '3rem',
                     lineHeight: '1',
                     margin: '1rem 0',
                     color: 'rgb(160, 148, 104)',
                  }}
               >
                  WELCOME TO THE FUTURE OF TOFFEE.
               </p>
               <p
                  style={{
                     fontSize: '1.8rem',
                     lineHeight: '1.2',
                     fontWeight: '300',
                     marginTop: '0',
                  }}
               >
                  Engineered to be an over the top experience for the palate, <b>Stellar Toffee</b> sets a new standard for sweet treats never previously achieved in any candy kitchen. We blush at words like daring and courageous, but that doesn’t
                  mean we think they’re wrong.
                  <br />
                  <br />
                  <b>
                     Each 8 oz box is $15 with free shipping in US
                     <br />
                     and a 10% discount for nine boxes or more.
                  </b>
               </p>
            </div>
         </div>
         <br />
         <br />

         <div
            style={{
               backgroundColor: '#ebebeb',
               padding: '70px 0 180px',
               position: 'relative',
            }}
         >
            <img src={starW} style={{ position: 'absolute', left: '20%', top: '30%' }} />
            <img
               src={starW}
               style={{
                  position: 'absolute',
                  right: '20%',
                  top: '61%',
                  width: '40px',
                  transform: 'rotate(20deg)',
               }}
            />
            <img
               src={starW}
               style={{
                  position: 'absolute',
                  right: '15%',
                  top: '20%',
                  transform: 'rotate(20deg)',
               }}
            />
            <div id="Buy" className="arrow-down"></div>
            <div className="row container center animatedParent">
               <div className="col s12 m8 offset-m2 l8 offset-l2 animated fadeInUpShort">
                  {/* {state === "form" ? (
							<Form onFinalize={handleFormSubmit} />
						) : (
							<Review form={form} />
						)} */}
                  <Link to="/Products" className="btn-large">
                     ORDER TOFFEE
                  </Link>
               </div>
            </div>
         </div>

         <div className="clearfix"></div>
      </div>
   );
};

export default HomePage;
