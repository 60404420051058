import React, { useEffect } from 'react';
import withAuthentication from './context/auth-provider';
import Route, { HomeRoute, PrivateRoute } from './routes';
import HomePage from './containers/HomePage';
import ProductPage from './containers/ProductPage';
import ContactPage from './containers/ContactPage';
import OurStoryPage from './containers/OurStoryPage';
import LoginPage from './containers/LoginPage';
import LocationsPage from './containers/LocationsPage';
import RecipesPage from './containers/RecipesPage';
import RecipesSplashPage from './containers/RecipesPage/splash';
import NotFoundPage from './containers/NotFoundPage';
import { BrowserRouter as Router, Switch, useLocation } from 'react-router-dom';
import WholeSalePage from './containers/WholeSalePage';
import CartPage from './containers/CartPage';
import BlogPage from './containers/BlogPage';
import CartCheckoutPage from './containers/CartPage/cart.checkout';
import CartReviewPage from './containers/CartPage/cart.review';
import CartConfirmationPage from './containers/CartPage/cart.confirmation';
// import categoryService from './services/categories';
// import Loader from './components/Loader';

export const ScrollToTop = () => {
   const { pathname } = useLocation();
   console.log({ pathname });
   useEffect(() => {
      window.scrollTo(0, 0);
   }, [pathname]);

   return null;
};

const LoginWithAuthData = withAuthentication(LoginPage);

const App = () => {
   return (
      <Router>
         <React.Fragment>
            <ScrollToTop />
            <Switch>
               <HomeRoute exact path="/" component={HomePage} />
               <HomeRoute path="/blog" component={BlogPage} />
               <HomeRoute exact path="/Products" component={ProductPage} />
               <HomeRoute exact path="/Cart" component={CartPage} />
               <HomeRoute exact path="/Cart.Checkout" component={CartCheckoutPage} />
               <HomeRoute exact path="/Cart.Review" component={CartReviewPage} />
               <HomeRoute exact path="/Cart.Confirmation" component={CartConfirmationPage} />
               <HomeRoute path="/success/:oid" component={CartConfirmationPage} />
               {/* <HomeRoute exact path="/Products/:category/:id" component={ProductPage} /> */}
               {/* 
					<HomeRoute path="/Our-Story" component={OurStoryPage} />
					<HomeRoute path="/Contact-Us" component={ContactPage} />
					<HomeRoute path="/Locations" component={LocationsPage} />
					<HomeRoute path="/Login" component={LoginWithAuthData} />
					<PrivateRoute path="/WholeSale" component={WholeSalePage} />
					<HomeRoute exact path="/Recipes" component={RecipesSplashPage} />
					<HomeRoute
						exact
						path="/Recipes/:category/:id"
						component={RecipesPage}
					/> */}
               <Route component={NotFoundPage} />
            </Switch>
         </React.Fragment>
      </Router>
   );
};

export default App;
