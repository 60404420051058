import config from './config';
import Cookies from 'js-cookie';
import Axios from 'axios';

export default class Data {
   api(path, method = 'GET', body = null, requiresAuth = false, credentials = null) {
      const url = config.apiBaseUrl + path;

      const options = {
         method,
         headers: {
            'Content-Type': 'application/json',
            // 'Access-Control-Allow-Origin': '*',
         },
      };

      if (body !== null) {
         options.body = JSON.stringify(body);
      }
      if (requiresAuth) {
         if ('username' in credentials && 'password' in credentials) {
            const encodedCredentials = btoa(`${credentials.username}:${credentials.password}`);
            options.headers['Authorization'] = ` Basic ${encodedCredentials}`;
         } else if ('token' in credentials) {
            const encodedCredentials = btoa(`${credentials.token}`);
            options.headers['Authorization'] = ` Bearer ${encodedCredentials}`;
         }
      }

      const response = Axios({ url, ...options });
      // const response = fetch(url, options);

      try {
         response.then((json) => {
            if (json.statusText === 'Unauthorized') {
               Cookies.remove('authToken');
            }
         });
      } catch (e) {
         console.log(e);
      }
      return response;
   }

   async getProducts(catid = 0) {
      return await this.api(`/products.cfc?method=getByCategory&catid=${catid}`, 'GET')
         .then((response) => {
            if (response.status === 200) {
               return response.data;
            } else {
               return [];
            }
         })
         .catch((err) => console.log({ err }));
   }
}
