import React from 'react';
import { useForm } from 'react-hook-form';
import M from 'materialize-css';
import axios from 'axios';

const Register = (props) => {
   const { handleSubmit, register, errors, getValues } = useForm();
   // const [username, setUsername] = useState("");
   // const [password, setPassword] = useState("");

   const onSubmit = (values) => {
      const { password, confirmPassword } = values;
      // const { auth, history } = props;
      const url = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' || window.location.hostname === '192.168.0.110' ? `http://${window.location.hostname}/cfc/api` : 'https://stellartoffee.com/cfc/api';

      if (password !== confirmPassword) {
         alert('Passwords must match');
         return false;
      }

      axios({
         method: 'POST',
         url: url + '/users.cfc?method=register',
         data: { ...values, role: 'wholesale' },
         headers: { 'Content-Type': 'application/json' },
      })
         .then((resp) => resp.data)
         .then((data) =>
            data.success
               ? M.toast({
                    html: 'Thank you for your submission, we will contact you shortly.',
                 })
               : M.toast({
                    html: 'Unable to compelete setup, does your account already exist?',
                 })
         );
      // .finally((data) => history.push('/login'));
   };

   return (
      <form name="register-form" onSubmit={handleSubmit(onSubmit)}>
         <div className="row form-holder">
            <div className="input-field col s6">
               <input
                  type="text"
                  className="input"
                  ref={register({
                     required: 'Company Name is required!',
                  })}
                  name="companyname"
                  id="companyname"
                  placeholder="Company Name"
               />
               {/* <label className="active" htmlFor="companyname">
						companyname
					</label> */}
               <span className="helper-text red-text">{errors.companyname && errors.companyname.message}</span>
            </div>
            <div className="input-field col s6">
               <input
                  type="text"
                  className="input"
                  ref={register({
                     required: 'Address is required!',
                  })}
                  name="address"
                  id="address"
                  placeholder="Address"
               />
               {/* <label className="active" htmlFor="address">
						address
					</label> */}
               <span className="helper-text red-text">{errors.address && errors.address.message}</span>
            </div>
            <div className="input-field col s6">
               <input
                  type="text"
                  className="input"
                  ref={register({
                     required: 'City is required!',
                  })}
                  name="city"
                  id="city"
                  placeholder="City"
               />
               {/* <label className="active" htmlFor="city">
						city
					</label> */}
               <span className="helper-text red-text">{errors.city && errors.city.message}</span>
            </div>
            <div className="input-field col s6">
               <input
                  type="text"
                  className="input"
                  ref={register({
                     required: 'State is required!',
                  })}
                  name="state"
                  id="state"
                  placeholder="State"
               />
               {/* <label className="active" htmlFor="state">
						state
					</label> */}
               <span className="helper-text red-text">{errors.state && errors.state.message}</span>
            </div>
            <div className="input-field col s6">
               <input
                  type="text"
                  className="input"
                  ref={register({
                     required: 'Zip is required!',
                  })}
                  name="zip"
                  id="zip"
                  placeholder="Zip"
               />
               {/* <label className="active" htmlFor="zip">
						zip
					</label> */}
               <span className="helper-text red-text">{errors.zip && errors.zip.message}</span>
            </div>
            <div className="input-field col s6">
               <input
                  type="text"
                  className="input"
                  ref={register({
                     required: 'Email is required!',
                  })}
                  name="email"
                  id="email"
                  placeholder="Email"
               />
               {/* <label className="active" htmlFor="email">
						email
					</label> */}
               <span className="helper-text red-text">{errors.email && errors.email.message}</span>
            </div>
            <div className="input-field col s6">
               <input
                  type="text"
                  className="input"
                  ref={register({
                     required: 'Phone Number is required!',
                  })}
                  name="phone"
                  id="phone"
                  placeholder="Phone Number"
               />
               {/* <label className="active" htmlFor="phone">
						phone
					</label> */}
               <span className="helper-text red-text">{errors.phone && errors.phone.message}</span>
            </div>
            <div className="input-field col s6">
               <input
                  type="text"
                  className="input"
                  ref={register({
                     required: 'Resale Number is required!',
                  })}
                  name="wholesale_number"
                  id="wholesale_number"
                  placeholder="Resale Number"
               />
               {/* <label className="active" htmlFor="wholesale_number">
						wholesale_number
					</label> */}
               <span className="helper-text red-text">{errors.wholesale_number && errors.wholesale_number.message}</span>
            </div>
            {/* <div className="input-field col s6">
               <input
                  type="text"
                  className="input"
                  ref={register({
                     required: 'Username is required!',
                     pattern: {
                        value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
                        message: 'Invalid Email Address',
                     },
                  })}
                  name="username"
                  id="reg_username"
                  placeholder="User Name"
               />
               <span className="helper-text red-text">
                  {errors.username && errors.username.message}
               </span>
            </div>
            <div className="input-field col s6">
               <input
                  type="password"
                  ref={register({ required: 'Password is required!' })}
                  className="input"
                  name="password"
                  id="reg_password"
                  placeholder="Password"
               />
               <span className="helper-text red-text">
                  {errors.password && errors.password.message}
               </span>
            </div>
            <div className="input-field col s6">
               <input
                  type="password"
                  ref={register({
                     required: 'Password is required!',
                     validate: {
                        matchesPreviousPassword: (value) => {
                           const { password } = getValues();
                           return password === value || 'Passwords should match!';
                        },
                     },
                  })}
                  name="confirmPassword"
                  id="confirmPassword"
                  className="input"
                  placeholder="Retype Password"
               />
               <span className="helper-text red-text">
                  {errors.confirmPassword && errors.confirmPassword.message}
               </span>
            </div> */}
         </div>
         <button type="submit" className="goldBtn submit-btn">
            Register
         </button>
      </form>
   );
};

export default Register;
