export const sumItems = (cartItems) => {
  const getStorage = () => localStorage.getItem('checkout');
  const storage = JSON.parse(getStorage());
  let itemCount = parseFloat(
    cartItems.reduce((total, product) => total + product.quantity, 0)
  );
  let merchTotal = parseFloat(
    cartItems
      .reduce((total, product) => total + product.price * product.quantity, 0)
      .toFixed(2)
  );
  let shipping =
    storage &&
    storage.shipping &&
    storage.shipping.country &&
    storage.shipping.country.toLowerCase() === 'usa'
      ? merchTotal * 0.07
      : 0;
  // let tax = storage && storage.shipping && storage.shipping.state && storage.shipping.state.toLowerCase() === 'ga' ? merchTotal * 0.07 : 0;
  let tax = 0;
  let total = merchTotal * (itemCount > 8 ? 0.9 : 1) + tax;
  let wholesaleTotal = parseFloat(
    cartItems
      .reduce(
        (total, product) =>
          product.name.indexOf('CASE :') >= 0
            ? total + product.price * product.quantity
            : total,
        0
      )
      .toFixed(2)
  );
  return { itemCount, shipping, merchTotal, tax, total, wholesaleTotal };
};

export const createReducer = () => CartReducer;

export const CartReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_ITEM':
      if (!state.cartItems.find((item) => item.id === action.payload.id)) {
        state.cartItems.push({
          ...action.payload,
          quantity: 1,
        });
      }

      return {
        ...state,
        ...sumItems(state.cartItems),
        cartItems: [...state.cartItems],
      };
    case 'SET_COUPON':
      return {
        ...state,
        ...action.payload,
      };
    case 'UPDATE':
      console.log('update');
      return {
        ...state,
        ...sumItems(state.cartItems),
      };
    case 'REMOVE_COUPON':
      return {
        ...state,
        coupon: null,
      };
    case 'ADD_WHOLESALE_ITEM':
      if (!state.cartItems.find((item) => item.id === action.payload.id)) {
        state.cartItems.push({
          ...action.payload,
        });
      } else {
        state.cartItems[
          state.cartItems.findIndex((item) => item.id === action.payload.id)
        ].quantity = action.payload.quantity;
      }

      return {
        ...state,
        ...sumItems(state.cartItems),
        cartItems: [...state.cartItems],
      };
    case 'REMOVE_ITEM':
      return {
        ...state,
        ...sumItems(
          state.cartItems.filter((item) => item.id !== action.payload.id)
        ),
        cartItems: [
          ...state.cartItems.filter((item) => item.id !== action.payload.id),
        ],
      };
    case 'INCREASE':
      console.log('reducer increase');
      state.cartItems[
        state.cartItems.findIndex((item) => item.id === action.payload.id)
      ].quantity++;
      return {
        ...state,
        ...sumItems(state.cartItems),
        cartItems: [...state.cartItems],
      };
    case 'DECREASE':
      state.cartItems[
        state.cartItems.findIndex((item) => item.id === action.payload.id)
      ].quantity--;
      if (
        state.cartItems[
          state.cartItems.findIndex((item) => item.id === action.payload.id)
        ].quantity <= 0
      ) {
        return {
          ...state,
          ...sumItems(
            state.cartItems.filter((item) => item.id !== action.payload.id)
          ),
          cartItems: [
            ...state.cartItems.filter((item) => item.id !== action.payload.id),
          ],
        };
      } else {
        return {
          ...state,
          ...sumItems(state.cartItems),
          cartItems: [...state.cartItems],
        };
      }
    case 'CHECKOUT':
      return {
        cartItems: [],
        checkout: true,
        ...sumItems([]),
      };
    case 'CLEAR':
      return {
        cartItems: [],
        ...sumItems([]),
      };
    default:
      return state;
  }
};
