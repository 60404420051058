import React, { useState, useEffect, useCallback, useContext } from 'react';
import Form from './cart.checkout.form';
import Loading from '../../components/Loader';
import M from 'materialize-css';
import { CartContext } from '../../context/CartContext';

const Page = (props) => {
   useEffect(() => {
      // M.Sidenav.init(document.getElementById('mobile-nav'));
      // const elems = document.querySelectorAll('.collapsible');

      const elems = document.querySelectorAll('select');
      const instances = M.FormSelect.init(elems, []);
   }, []);

   const { doUpdate } = useContext(CartContext);
   const [form, setForm] = useState(null);

   const placeholder = {
      billing: {
         company_name: '',
         firstname: '',
         lastname: '',
         address1: '',
         address2: '',
         city: '',
         state: '',
         zip: '',
         country: '',
         email: '',
         phone: '',
      },
      shipping: {
         firstname: '',
         lastname: '',
         address1: '',
         address2: '',
         city: '',
         state: '',
         zip: '',
         country: '',
         email: '',
         phone: '',
      },
      payment: {
         cc: '',
         month: '',
         year: '',
         cvv: '',
      },
      notes: '',
      sameAsBilling: false,
   };

   const fetchData = () => {
      const getStorage = () => localStorage.getItem('checkout');
      const storage = JSON.parse(getStorage());
      setForm(storage ? storage : placeholder);
   };

   useEffect(() => {
      fetchData();
   }, []);

   const updateData = () => {
      console.log('update data');
      doUpdate();
      fetchData();
   };

   // const onSubmit = (values) => console.log(values);
   console.log({ form });
   return (
      <div className="">
         <div className="row container">
            {/* <h1 className="center">Checkout</h1> */}
            {form ? <Form updateData={updateData} data={form} {...props} /> : <Loading />}
         </div>
         <br />
         <br />
      </div>
   );
};

export default Page;
