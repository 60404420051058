import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import M from 'materialize-css';
import './store.css';
import PaypalButton from './paypal_button';
import Axios from 'axios';
import { CartContext } from '../../context/CartContext';
import { API_URL } from '../../config';
import Confirmation from './cart.confirmation.paypal';
// import sauce01 from './image268809.png';
// import sauce02 from './image976330.png';
import Cart from './index';
import CryptoJS from 'crypto-js';
import hmacSHA1 from 'crypto-js/sha1';
import Base64 from 'crypto-js/enc-base64';
import moment from 'moment';

const CLIENT = {
   sandbox: 'AfyYYswWAFV9yOafrefQnllVfWuX7WwdrkTau3-3IznNidFYutjvYgiwhIDptG6N7PuOg-owzUuMieGR',
   production: 'AbjRZUyXWkaRUyD_NGo0s1CznLsqgxYci30WP-gQYQck0GDF7Js3FKFINSpuT1O_VkzNvFi4pj2ydjOc',
};

const ENV = process.env.NODE_ENV === 'production' ? 'production' : 'sandbox';

const round = (number, decimalPlaces) => Number(Math.round(number + 'e' + decimalPlaces) + 'e-' + decimalPlaces);

const perBoxPrice = 15;

const placeholder = {
   billing: {
      company_name: '',
      firstname: '',
      lastname: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      email: '',
      phone: '',
   },
   shipping: {
      firstname: '',
      lastname: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      email: '',
      phone: '',
   },
   payment: {
      cc: '',
      month: '',
      year: '',
      cvv: '',
   },
   notes: '',
   sameAsBilling: false,
};

const CartPagee = ({ history }) => {
   const [data, setData] = useState(placeholder);
   const [processing, setProcessing] = useState(false);
   const [payeezy, setPayeezy] = useState(null);
   const [state, setState] = useState('form');
   const [order, setOrder] = useState(null);

   const { cartItems, clearCart, total, tax, coupon } = useContext(CartContext);

   const cart = cartItems;

   useEffect(() => {
      const fetchData = async () => {
         const getStorage = () => localStorage.getItem('checkout');
         const storage = JSON.parse(await getStorage());
         setData(storage ? storage : placeholder);
      };

      fetchData();
   }, []);

   const doPlaceOrder = () => {
      if (processing) return M.toast({ html: 'Order Processing, please wait' });

      setProcessing(true);
      // submit to server
      const apiBaseUrl = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' || window.location.hostname === '192.168.0.110' ? `http://${window.location.hostname}` : 'https://stellartoffee.com';

      const url = `${apiBaseUrl}/cfc/api/orders.cfc?method=placeorder`;

      console.log('posting', { cart, data }, 'to ', { url });

      Axios({
         method: 'POST',
         url: url,
         data: {
            cart,
            data: { ...data, total, coupon },
         },
         headers: { 'Content-Type': 'application/json' },
      })
         .then((res) => res.data)
         .then((order) => {
            if (order.success) {
               const saveOrderLocal = async () => {
                  const getLocalOrders = () => localStorage.getItem('orders');
                  let orders = JSON.parse(await getLocalOrders());
                  if (orders) {
                     orders.push({ cart, data, order });
                  } else {
                     orders = [{ cart, data, order }];
                  }
                  localStorage.setItem('orders', JSON.stringify(orders));
                  setProcessing(false);
                  clearCart();
                  history.push('/cart.confirmation');
               };

               saveOrderLocal();
            }
         })
         .finally((e) => setProcessing(false));
      // on success move cart to past ordered local
      // redirect to confirmation
   };

   const { billing, shipping, payment } = data ? data : placeholder;

   const payeezyRun = () => {
      const login = 'WSP-TOFFE-wYFHSQDY6w';
      const key = 'A9_l9srG82WbdS9vQUED';
      const timestamp = moment().unix() + (moment().isDST() ? 1000 : 0);
      const amount = total;
      const currency_code = 'USD';
      const line_items = '';

      const max = 999999999;
      const sequence = Math.floor(Math.random() * max) + 1;
      const invoice = '000001';

      const hmac_data = `${login}^${sequence}^${timestamp}^${amount}^${currency_code}`;

      const hash = CryptoJS.HmacSHA1(hmac_data, key);
      const digest_hash = Base64.stringify(hmacSHA1(hash));

      Axios({
         method: 'POST',
         url: `${API_URL}orders.cfc?method=peinit`,
         data: {
            data: {
               ...data,
               order: {
                  email: data.billing.email,
                  phone: data.billing.phone,
                  amount,
                  tax,
                  message: data.notes,
               },
            },
            cart,
            payeezy: {
               login,
               amount,
               sequence,
            },
         },
      })
         .then((resp) => resp.status === 200 && resp.data)
         .then((data) => {
            if (data.success) {
               setPayeezy({
                  timestamp: data.timestamp,
                  login,
                  amount,
                  tax,
                  sequence,
                  hash: data.payeezy.hash,
                  id: data.order.orderId,
               });
            }
         });
   };

   const onPaypalSuccess = (x) => {
      console.log({ x });
      /* 
      {
         cancelled : false,
         paid : true,
         payerId: xxx
         papymentId: xxx
         paymentToken: xxx
         returnUrl
      }
      */

      x.cancelled === false &&
         x.paid === true &&
         Axios({
            method: 'POST',
            url: `${API_URL}orders.cfc?method=pppurchase`,
            data: {
               data: {
                  ...data,
                  order: {
                     email: data.billing.email,
                     phone: data.billing.phone,
                     amount: total,
                     tax,
                     message: data.notes,
                  },
               },
               cart,
               x: x,
            },
         })
            .then((resp) => resp.status === 200 && resp.data)
            .then((data) => {
               if (data.success) {
                  // setForm({
                  //    ...form,
                  //    order: { ...form.order, orderId: data.orderId },
                  // });
                  setOrder({ ...order, orderId: data.orderId });
                  setState('ppsuccess');
                  window.scrollTo(0, 0);
               }
            });
   };
   const onPaypalError = (e) => {
      console.log('error', { e });
   };
   const onPaypalCancel = (e) => {
      console.log('cancel', { e });
   };

   if (state === 'ppsuccess') {
      return <Confirmation order={{ ...data, order }} />;
   }

   const openPayeezyForm = () => {
      payeezyRun();
   };

   return (
      <div className="">
         {payeezy && <PayeezyForm {...payeezy} order={data} cart={cart} />}

         {/* <h1 className="center">Checkout Review</h1> */}

         <Cart editable={false} checkout={data} />

         <div className="row container">
            <div className="col s12 m4 l4">
               <h4>Shipping To:</h4>
               <p>
                  {`${shipping.firstname} ${shipping.lastname}`}
                  <br />
                  {shipping.address1}
                  {shipping.address2 && `<br />${shipping.address2}`}
                  <br />
                  {`${shipping.city}, ${shipping.state} ${shipping.zip}`}
                  <br />
                  {shipping.country}
                  <br />
                  {shipping.phone}
                  <br />
                  {shipping.email}
               </p>
            </div>
            <div className="col s12 m12 l4">
               <h4>Billing To:</h4>
               <p>
                  {billing.company_name}
                  <br />
                  {`${billing.firstname} ${billing.lastname}`}
                  <br />
                  {billing.address1}
                  {billing.address2 && `<br />${billing.address2}`}
                  <br />
                  {`${billing.city}, ${billing.state} ${billing.zip}`}
                  <br />
                  {billing.country}
               </p>
               <form></form>
            </div>
            <div className="col s12 m12 l4">
               <p>
                  Payment with : {payment.paymentType === 'card' && 'Card'}
                  {payment.paymentType === 'paypal' && 'Paypal'}
               </p>
               {/* <h4>Card Info:</h4>
            <p>
               ****{payment.cc.substring(payment.cc.length - 4, payment.cc.length)}
               <br />
               {`${payment.month}/${payment.year}`}
            </p> */}
            </div>

            <div className="clearfix"></div>
            <div className="col s12 m6 l6">
               <br />
               <div className="center">
                  <Link to="/cart.checkout" className="btn" type="submit">
                     Edit Billing/Shipping
                  </Link>
               </div>
            </div>
            <div className="col s12 m6 l6">
               <br />
               <div className="center">
                  {
                     processing === false && (
                        <>
                           {payment.paymentType === 'paypal' && <PaypalButton client={CLIENT} env={ENV} commit={true} currency={'USD'} total={round(total, 2)} onSuccess={onPaypalSuccess} onError={onPaypalError} onCancel={onPaypalCancel} />}

                           {payment.paymentType === 'card' && (
                              <button onClick={openPayeezyForm} className="btn" type="button">
                                 Continue to Payment
                              </button>
                           )}
                        </>
                     )
                     // <button className="btn" type="button" onClick={() => doPlaceOrder()}>
                     //    Place Order
                     // </button>
                  }
                  {processing === true && (
                     <div className="progress">
                        <div className="indeterminate"></div>
                     </div>
                  )}
               </div>
            </div>
         </div>
         <div className="clearfix"></div>
         <br />
         <br />
      </div>
   );
};

const PayeezyForm = ({ order, tax, id, timestamp, login, amount, sequence, hash, cart }) => {
   // console.log({ order });
   // console.log({ id });
   // console.log({ timestamp });
   // console.log({ login });
   // console.log({ amount });
   // console.log({ sequence });
   // console.log({ hash });
   // console.log({ cart });

   useEffect(() => {
      const form = document.getElementById('pay_now_form_3a4a8c5609');

      if (form) {
         // console.log({ order, timestamp, login, amount, sequence, hash });
         form.submit();
      }
   }, []);

   const total_items = cart.reduce((total, x) => (total += x.quantity), 0);
   const cart_total = cart.reduce((total, x) => (total += x.price * x.quantity), 0);

   const lineItem = ({ quantity, name, price, title, description, amount, ...rest }) => {
      const discount_amount = total_items > 8 ? price - price * 0.9 : 0;
      const line_total = total_items > 8 ? quantity * price * 0.9 : quantity * price;
      const line_price = total_items > 8 ? price * 0.9 : price;

      const desc = 'Stellar Toffee is a delicious departure from everything you thought you knew about toffee.';
      // const item = ['001', name, name, quantity, price, 'N', '', '', '', '', 0, '', discount_amount, line_total];
      const item = ['001', name, name, quantity, line_price, 'N', '', '', '', '', 0, '', 0, quantity * line_price];

      return item.join('<|>');
   };

   return (
      <form action="https://checkout.globalgatewaye4.firstdata.com/pay" id="pay_now_form_3a4a8c5609" method="post">
         <input type="hidden" name="x_login" value={login} />
         <input type="hidden" name="x_show_form" value="PAYMENT_FORM" />
         <input type="hidden" name="x_fp_sequence" value={sequence} />
         <input type="hidden" name="x_fp_timestamp" value={timestamp} />
         <input type="hidden" name="x_fp_hash" value={hash} />
         <input type="hidden" name="x_amount" value={amount} />
         {/* {total_items > 8 && <input type="hidden" name="discount_amount" value={round(cart_total - cart_total * 0.9, 2)} />} */}
         <input type="hidden" name="x_tax" value={round(tax, 2)} />
         <input type="hidden" name="x_currency_code" value="USD" />
         <input type="hidden" name="x_test_request" value="FALSE" />
         <input type="hidden" name="x_po_num" value={`${id}`} />
         <input type="hidden" name="x_first_name" value={order.billing.firstname} />
         <input type="hidden" name="x_last_name" value={order.billing.lastname} />
         <input type="hidden" name="x_address" value={order.billing.address1} />
         <input type="hidden" name="x_city" value={order.billing.city} />
         <input type="hidden" name="x_state" value={order.billing.state} />
         <input type="hidden" name="x_zip" value={order.billing.zip} />
         <input type="hidden" name="x_country" value={order.billing.country} />
         <input type="hidden" name="x_phone" value={order.billing.phone} />
         <input type="hidden" name="x_email" value={order.billing.email} />
         {cart.map((x, i) => (
            <>
               <input type="hidden" name="x_line_item" value={lineItem({ ...x })} />
            </>
         ))}
         <input type="hidden" name="x_logo_url" value="https://stellartoffee.com/assets/images/logo/logo.png" />
         <input type="hidden" name="donation_prompt" />
         <input type="hidden" name="x_type" value="AUTH_CAPTURE" />
         <input type="hidden" name="button_code" value="Pay Now Stellar Toffee" />
      </form>
   );
};

export default CartPagee;
