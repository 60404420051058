// import 'react-app-polyfill/ie9';
import 'core-js/stable';
// import 'core-js/features/object/assign';
// import 'core-js/features/promise';
// import 'core-js/features/string/ends-with';
// import 'core-js/features/symbol/for';
// import 'core-js/features/weak-set';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
// import ''
import './styles/materializeOverride.scss';
import './styles/materialize.modern.admin.scss';
import App from './app';
import { Provider } from './context/auth-provider';
import CartContextProvider from './context/CartContext';

ReactDOM.render(
   <React.StrictMode>
      <CartContextProvider>
         <Provider>
            <Router>
               <App />
            </Router>
         </Provider>
      </CartContextProvider>
   </React.StrictMode>,
   document.getElementById('root')
);
