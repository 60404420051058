import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import './store.css';
import { CartContext } from '../../context/CartContext';
import CurrencyFormat from 'react-currency-format';
import { useForm } from 'react-hook-form';
import axios from 'axios';

const round = (number, decimalPlaces) =>
  Number(Math.round(number + 'e' + decimalPlaces) + 'e-' + decimalPlaces);
const currencyAttr = {
  decimalScale: 2,
  fixedDecimalScale: true,
  displayType: 'text',
  thousandSeparator: true,
  prefix: '$',
};

const CartPage = ({ editable = true, checkout = null }) => {
  const {
    tax,
    coupon,
    removeCoupon,
    setCoupon,
    total,
    wholesaleTotal,
    itemCount,
    cartItems,
    increase,
    decrease,
    merchTotal,
    removeProduct,
  } = useContext(CartContext);
  // const { handleSubmit, setValue, register, errors } = useForm({
  //    defaultValues: { couponCode: coupon && coupon.code && coupon.code.length ? coupon.code : '' },
  // });

  console.log({ merchTotal });

  const { handleSubmit, setValue, register, errors } = useForm();

  const nonWholeSaleTotal = total - wholesaleTotal;
  const cart = cartItems;
  const shippingCost = 0;
  // const taxCost = checkout && checkout.shipping.state.toLowerCase() === 'ga' ? total * 0.07 : 0;
  const taxCost = tax;

  // useEffect(() => {
  //    const storageCoupon = localStorage.getItem('coupon');
  //    const pulledCoupon = storageCoupon ? JSON.parse(storageCoupon) : null;
  //    if (pulledCoupon) setCoupon(JSON.parse(pulledCoupon));
  // });

  const checkCouponCode = async (value) => {
    if (value.length === 0) return;

    const apiBaseUrl =
      window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1' ||
      window.location.hostname === '192.168.0.110'
        ? `http://${window.location.hostname}`
        : 'https://stellartoffee.com';
    const url = `${apiBaseUrl}/cfc/api/coupons.cfc?method=getCouponByCode&code=${value}`;
    return await axios
      .get(url)
      .then((response) => response.status === 200 && response.data)
      .then((response) => response.success && response.coupon)
      .then((coup) => {
        if (coup && coup.id > 0) {
          console.log({ coup });
          // validate coup
          if (coup.minimum > 0 && coup.minimum < total)
            return 'Minumum not reached';

          if (coup.productRestrictions.length > 0) {
            let match = false;
            const restrictions = coup.productRestriction.split(',');
            restrictions.forEach((condition, i) => {
              if (match) return true;

              let con = condition.split('*').join('.*');
              con = con.indexOf('.*') > 0 ? `^${con}` : con;
              con = con.endsWith('.*') ? con : `${con}$`;
              console.log({ con });

              match = cartItems.reduce((t, i) => {
                if (t) return true;

                let re = new RegExp(con, 'g');
                let _match = i.sku.match(re, 'regex');

                if (_match) return true;
                return false;
              }, false);
            });
            if (!match) {
              return 'None of your products match the coup product restrictions';
            }
          }

          setCoupon({ coupon: coup });
          localStorage.setItem('coupon', JSON.stringify({ coupon: coup }));
          console.log('coup return', { coup });
        } else {
          return 'Invalid code!';
        }
      });
  };

  const onSubmit = (values) => {
    const couponForm = { ...values };

    console.log({ couponForm });

    checkCouponCode(values.couponCode);

    // localStorage.setItem('checkout', JSON.stringify(fData));
    // history.push('/cart.review');
  };

  const getTotalAfterCoupon = (total) => {
    try {
      if (coupon) {
        if (coupon.type.toLowerCase() === 'percent') {
          // return total * ((100 - coupon.percent) / 100);
          console.log(
            'percent',
            Math.floor(total * (coupon.percent / 100) * 100) / 100
          );
          return total - Math.floor(total * (coupon.percent / 100) * 100) / 100;
        } else {
          return total - coupon.amount;
        }
      }
      console.log({ coupon });
    } catch (e) {}

    return total;
  };

  // console.log('state coupon = ', { coupon });

  return (
    <div className="row container">
      {/* <h1 className={`center ${!editable && 'hide'}`}>Your Cart</h1> */}
      <div className="col s12 m12 l8">
        <h4>Item(s) in Cart: {itemCount}</h4>
        {cart.map((item, i) => (
          <div key={i} className="card horizontal z-depth-1">
            <div className="col s5 l4 card-image">
              <img
                alt=""
                src={`https://stellartoffee.com/assets/products/large/${item.image}`}
                style={{ maxHeight: '190px' }}
              />
            </div>
            <div className="col s7 l8 card-stacked" style={{ padding: '0' }}>
              <div className="card-content">
                {editable && (
                  <span className="remove" onClick={() => removeProduct(item)}>
                    Remove
                  </span>
                )}
                <p>
                  <b>{item.name}</b>
                </p>
              </div>
              <div className="card-action hide-on-small-only">
                {editable && (
                  <span onClick={() => decrease(item)} className="qt-minus">
                    -
                  </span>
                )}
                <span className="qt">{item.quantity}</span>
                {editable && (
                  <span onClick={() => increase(item)} className="qt-plus">
                    +
                  </span>
                )}
                <span className="full-price right">
                  <CurrencyFormat
                    {...currencyAttr}
                    value={round(item.quantity * item.price, 2)}
                  />
                </span>
                <span className="price right">
                  <CurrencyFormat
                    {...currencyAttr}
                    value={round(item.price, 2)}
                  />{' '}
                  Unit Price
                </span>
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="col s12 card-action hide-on-med-and-up">
              {editable && (
                <span onClick={() => decrease(item)} className="qt-minus">
                  -
                </span>
              )}
              <span className="qt">{item.quantity}</span>
              {editable && (
                <span onClick={() => increase(item)} className="qt-plus">
                  +
                </span>
              )}
              <span className="full-price right">
                <CurrencyFormat
                  {...currencyAttr}
                  value={round(item.quantity * item.price, 2)}
                />
              </span>
              <span className="price right">
                <CurrencyFormat
                  {...currencyAttr}
                  value={round(item.price, 2)}
                />{' '}
                Unit Price
              </span>
            </div>
          </div>
        ))}
        <div className="clearfix"></div>
      </div>
      <div className="col s12 m12 l4">
        <h4>Cart Summary</h4>
        <p>
          Merchandise Subtotal
          <span className="right">
            <CurrencyFormat
              {...currencyAttr}
              value={round(getTotalAfterCoupon(merchTotal), 2)}
            />
          </span>
        </p>
        {cart.length > 0 && (wholesaleTotal === 0 || wholesaleTotal < total) && (
          <p style={{ lineHeight: '1.2' }}>
            Shipping
            <br />
            <span style={{ fontSize: '14px' }}>
              Free shipping in US
              <br />
              10% discount for nine boxes or more
            </span>
            <span className="right">
              <CurrencyFormat {...currencyAttr} value={round(0, 2)} />
            </span>
          </p>
        )}
        {!editable && taxCost > 0 && (
          <p>
            Tax
            <span className="right">
              <CurrencyFormat {...currencyAttr} value={round(taxCost, 2)} />
            </span>
          </p>
        )}
        {false && editable && (
          <div style={{ position: 'relative' }}>
            <p style={{ marginBottom: '0' }}>
              Add Promo Code
              <span className="right red-text helper-text">
                {errors.couponCode && errors.couponCode.message}
              </span>
            </p>
            <form name="coupon-form" onSubmit={handleSubmit(onSubmit)}>
              <input
                type="text"
                placeholder="Code"
                className="rightTagInput"
                name="couponCode"
                id="couponCode"
                // value={coupon}
                ref={register({ required: 'Coupon Code required' })}
              />
              {coupon ? (
                <button
                  type="button"
                  onClick={() => {
                    setValue('couponCode', '');
                    removeCoupon();
                  }}
                  className="goldBtn inputTag"
                >
                  REMOVE
                </button>
              ) : (
                <button type="submit" className="goldBtn inputTag">
                  SUBMIT
                </button>
              )}
            </form>
          </div>
        )}
        <div className="clearfix"></div>
        {editable === true ? (
          <p className="totalText">
            Total (USD):
            <span className="right">
              <CurrencyFormat
                {...currencyAttr}
                value={round(getTotalAfterCoupon(total) + shippingCost, 2)}
              />
            </span>
          </p>
        ) : (
          <p className="totalText">
            Grand Total (USD):
            <span className="right">
              <CurrencyFormat
                {...currencyAttr}
                value={round(getTotalAfterCoupon(total) + shippingCost, 2)}
              />
            </span>
          </p>
        )}
        {editable && (
          <div className="center">
            <Link to="cart.checkout" className="btn" type="submit">
              Proceed to Secure Checkout
            </Link>
          </div>
        )}
      </div>

      <div className="clearfix"></div>
      <br />
      <br />
    </div>
  );
};

export default CartPage;
